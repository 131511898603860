<template>
  <div id="app">
    <router-view/>
  </div>
  <TooltipContainer/>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {v4 as uuidv4} from 'uuid';
import {WebStorage} from "@/sharedAssets/model/enums/WebStorage";
import TooltipContainer from "@/Application/components/tooltip/TooltipContainer.vue";

@Options({
  components: {TooltipContainer}
})
export default class App extends Vue {
  mounted() {
    this.setSessionId();
    this.setUserId()
  }

  setSessionId() {
    try {
      if (!sessionStorage.getItem(WebStorage.SESSION_ID)) {
        sessionStorage.setItem(WebStorage.SESSION_ID, uuidv4());
      }
    } catch (e) {
      console.log(e)
    }
  }

  setUserId() {
    try {
      if (!localStorage.getItem(WebStorage.USER_ID)) {
        localStorage.setItem(WebStorage.USER_ID, uuidv4());
      }
    } catch (e) {
      console.log(e)
    }
  }
}
</script>

<style>
#app {
  font-family: Nunito, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*  margin-top: min(4rem, 5vh); */
}

@font-face {
  font-family: Nunito;
  font-display: block;
  src: url('~@/sharedAssets/Fonts/Nunito/Nunito-VariableFont_wght.woff2') format('woff2'),
  url('~@/sharedAssets/Fonts/Nunito/Nunito-VariableFont_wght.woff') format('woff'),
  url('~@/sharedAssets/Fonts/Nunito/Nunito-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: NunitoSemiBold;
  font-display: block;
  src: url('~@/sharedAssets/Fonts/Nunito/static/Nunito-SemiBold.woff2') format('woff2'),
  url('~@/sharedAssets/Fonts/Nunito/static/Nunito-SemiBold.woff') format('woff'),
  url('~@/sharedAssets/Fonts/Nunito/static/Nunito-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: NunitoBold;
  font-display: block;
  src: url('~@/sharedAssets/Fonts/Nunito/static/Nunito-Bold.woff2') format('woff2'),
  url('~@/sharedAssets/Fonts/Nunito/static/Nunito-Bold.woff') format('woff'),
  url('~@/sharedAssets/Fonts/Nunito/static/Nunito-Bold.ttf') format('truetype');
}

@font-face {
  font-family: NunitoExtraBold;
  font-display: block;
  src: url('~@/sharedAssets/Fonts/Nunito/static/Nunito-ExtraBold.woff2') format('woff2'),
  url('~@/sharedAssets/Fonts/Nunito/static/Nunito-ExtraBold.woff') format('woff'),
  url('~@/sharedAssets/Fonts/Nunito/static/Nunito-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: Poppins;
  src: url('~@/sharedAssets/Fonts/Poppins/Poppins-Regular.woff2') format('woff2'),
  url('~@/sharedAssets/Fonts/Poppins/Poppins-Regular.woff') format('woff'),
  url('~@/sharedAssets/Fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Inter;
  src: url('~@/sharedAssets/Fonts/Inter/Inter-VariableFont_slnt,wght.woff2') format('woff2'),
  url('~@/sharedAssets/Fonts/Inter/Inter-VariableFont_slnt,wght.woff') format('woff'),
  url('~@/sharedAssets/Fonts/Inter/Inter-VariableFont_slnt,wght.ttf') format('truetype');
}

@font-face {
  font-family: InterSemiBold;
  src: url('~@/sharedAssets/Fonts/Inter/static/Inter-SemiBold.woff2') format('woff2'),
  url('~@/sharedAssets/Fonts/Inter/static/Inter-SemiBold.woff') format('woff'),
  url('~@/sharedAssets/Fonts/Inter/static/Inter-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: RobotoReg;
  src: url('~@/sharedAssets/Fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
  url('~@/sharedAssets/Fonts/Roboto/Roboto-Regular.woff') format('woff'),
  url('~@/sharedAssets/Fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

/* nedanför används ej

@font-face {
  font-family: RobotoLight;
  src:url('~@/sharedAssets/Fonts/Roboto/Roboto-Light.ttf');
}

@font-face {
  font-family: RobotoMed;
  src:url('~@/sharedAssets/Fonts/Roboto/Roboto-Medium.ttf');
}

@font-face {
  font-family: Open_Sans;
  src:url('~@/sharedAssets/Fonts/Open_Sans/OpenSans-VariableFont_wdth,wght.ttf');
}

@font-face {
  font-family: Montserrat;
  src:url('~@/sharedAssets/Fonts/Montserrat/Montserrat-VariableFont_wght.ttf');
}


 */

</style>
