import axios from "axios";
import {QueryResponse} from "@/Application/model/QueryResponse";
import HttpError from "@/Application/exceptions/HttpError";
import {SortOrder} from "@/Application/model/SortOrder";


class ApiClient {
    static URL: string = "https://api.pearch.se/"

    async query(queryString: string, limitQuery: boolean, sortOrder: SortOrder): Promise<QueryResponse> {
        const url = ApiClient.URL + "query";

        // setup GET query params
        const options = {
            params: {
                queryString: queryString,
                limitedQuery: limitQuery,
                sortOrder: sortOrder,
            }
        }

        return axios.get(url, options)
            .then((response): QueryResponse => {
                const queryResponse = response.data as QueryResponse;
                //console.log(`queryResponse: ${JSON.stringify(queryResponse)}`);
                return queryResponse;
                }
            )
            .catch(e => {
                const statusCode = e.response.status;
                let message;
                console.log(e);
                if (statusCode === 300) {
                    message = "Prova en längre sökterm"
                } else {
                    message = "Något gick snett..."
                }
                throw new HttpError(statusCode, message);
            })

    }

}

export default new ApiClient();
