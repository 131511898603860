import axios from "axios";
import {InsightUrl} from "@/sharedAssets/model/enums/InsightUrl";
import {WebStorage} from "@/sharedAssets/model/enums/WebStorage";

class InsightHandler {

    static URL: string = InsightUrl.INSIGHT_URL

    static pageViewInsight(podcast: string, page: string, isTouchScreen: boolean) {
        const body = {
            type: "pageView",
            data: {
                "podcast": podcast,
                "page": page,
                "isTouchScreen": isTouchScreen,
                "sessionId": InsightHandler.getSessionId(),
                "userId": InsightHandler.getUserId()
            }
        }
        this.sendPostRequest(InsightHandler.URL, body)
    }

    static platformClickInsight(podcast: string, query: string | undefined, episodeNumber: number | undefined, episodeTitle: string | undefined, platform: string) {
        const body = {
            type: "platformClick",
            data: {
                "podcast": podcast,
                "query": query,
                "episodeNumber": episodeNumber,
                "episodeTitle": episodeTitle,
                "platform": platform,
                "sessionId": InsightHandler.getSessionId(),
                "userId": InsightHandler.getUserId()
            }
        }
        this.sendPostRequest(InsightHandler.URL, body)
    }


    static queryInsight(podcast: string, query: string, queryPage: string | undefined) {
        const body = {
            type: "query",
            data: {
                "podcast": podcast,
                "query": query,
                "queryPage": queryPage,
                "sessionId": InsightHandler.getSessionId(),
                "userId": InsightHandler.getUserId()
            }
        }
        this.sendPostRequest(InsightHandler.URL, body)
    }

    static timestampClickInsight(podcast: string, query: string | undefined, episodeNumber: number | undefined, episodeTitle: string | undefined, timestamp: number | undefined, platform: string) {
        const body = {
            type: "timestampClick",
            data: {
                "podcast": podcast,
                "query": query,
                "episodeNumber": episodeNumber,
                "episodeTitle": episodeTitle,
                "timestamp": timestamp,
                "platform": platform,
                "sessionId": InsightHandler.getSessionId(),
                "userId": InsightHandler.getUserId()
            }
        }
        this.sendPostRequest(InsightHandler.URL, body)
    }

    static visaFlerClickInsight(podcast: string, query: string | null) {
        const body = {
            type: "visaFlerClick",
            data: {
                "podcast": podcast,
                "query": query,
                "sessionId": InsightHandler.getSessionId(),
                "userId": InsightHandler.getUserId()
            }
        }
        this.sendPostRequest(InsightHandler.URL, body)
    }

    static episodeClickInsight(podcast: string, query: string | undefined, episodeNumber: number | undefined, episodeTitle: string | undefined) {
        const body = {
            type: "episodeClick",
            data: {
                "podcast": podcast,
                "query": query,
                "episodeNumber": episodeNumber,
                "episodeTitle": episodeTitle,
                "sessionId": InsightHandler.getSessionId(),
                "userId": InsightHandler.getUserId()
            }
        }
        this.sendPostRequest(InsightHandler.URL, body)
    }

    static infoTextViewInsight(podcast: string, page: string) {
        const body = {
            type: "infoTextView",
            data: {
                "podcast": podcast,
                "page": page,
                "sessionId": InsightHandler.getSessionId(),
                "userId": InsightHandler.getUserId()
            }
        }
        this.sendPostRequest(InsightHandler.URL, body)
    }

    static landingPageClickInsight(podcast: string, page: string, button: string) {
        const body = {
            type: "landingPageClick",
            data: {
                "podcast": podcast,
                "page": page,
                "button": button,
                "sessionId": InsightHandler.getSessionId(),
                "userId": InsightHandler.getUserId()
            }
        }
        this.sendPostRequest(InsightHandler.URL, body)
    }

    static sortByChanged(podcast: string, page: string, sortOrder: string) {
        const body = {
            type: "sortByChanged",
            data: {
                "podcast": podcast,
                "page": page,
                "sortOrder": sortOrder,
                "sessionId": InsightHandler.getSessionId(),
                "userId": InsightHandler.getUserId()
            }
        }
        this.sendPostRequest(InsightHandler.URL, body)
    }

    static landingPageViewInsight(page: string, isTouchScreen: boolean) {
        const body = {
            type: "landingPageView",
            data: {
                "page": page,
                "isTouchScreen": isTouchScreen,
                "sessionId": InsightHandler.getSessionId(),
                "userId": InsightHandler.getUserId()
            }
        }
        this.sendPostRequest(InsightHandler.URL, body)
    }

    static buttonClickInLandingPageInsight(page: string, button: string) {
        const body = {
            type: "buttonClickInLandingPage",
            data: {
                "page": page,
                "button": button,
                "sessionId": InsightHandler.getSessionId(),
                "userId": InsightHandler.getUserId()
            }
        }
        this.sendPostRequest(InsightHandler.URL, body)
    }

    static getSessionId() {
        try {
            return sessionStorage.getItem(WebStorage.SESSION_ID)
        } catch (e) {
            return null;
        }
    }

    static getUserId() {
        try {
            return localStorage.getItem(WebStorage.USER_ID)
        } catch (e) {
            return null;
        }
    }

    static sendPostRequest(url: string, body: any) {
        if (InsightHandler.isInsightsDisabled()) {
            return
        }

        axios.post(url, body)
            .then((response) => {
                return response.data;
            })
            .catch(e => {
                console.log(e);
            });
    }

    static isInsightsDisabled(): boolean {
        try {
            return localStorage.getItem(WebStorage.INSIGHTS_DISABLED) !== null
        } catch (e) {
            return false;
        }
    }

}

export default InsightHandler;






