<template>
<div></div>
</template>

<script>
export default {
  name: "EmptyPage"
}
</script>

<style scoped>

</style>