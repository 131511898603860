import TooltipContainerComponentPublicInstance
    from "@/Application/components/tooltip/TooltipContainerComponentPublicInstance";
import { tooltipDictionary } from "@/Application/components/tooltip/TooltipDictionary";

export default class TooltipManager {
    private component: TooltipContainerComponentPublicInstance;
    private static _instance: TooltipManager;

    static setInstance(component: TooltipContainerComponentPublicInstance) {
        TooltipManager._instance = new TooltipManager(component);
    }

    static instance(): TooltipManager {
        return TooltipManager._instance;
    }

    constructor(component: TooltipContainerComponentPublicInstance) {
        this.component = component;
    }

    show(key: string, objectToFocusQuery: string, after: number = 0) {
        const hasBeenShown = TooltipManager.hasTooltipBeenShown(key);
        if (hasBeenShown || !(key in tooltipDictionary)) {
            return;
        }

        const tooltipTitle = tooltipDictionary[key].title;
        const tooltipMessage = tooltipDictionary[key].message;
        const me = this;

        setTimeout(function () {
            const elementToFocus = document.querySelector(objectToFocusQuery);
            if (!(elementToFocus instanceof HTMLElement)) return;
            me.component.show(elementToFocus, tooltipTitle, tooltipMessage);
            TooltipManager.setTooltipHasBeenShown(key, true);
        }, after);

    }

    static hasTooltipBeenShown(tooltipKey: string): boolean {
        try {
            return localStorage.getItem(tooltipKey) !== null
        } catch (e) {
            return true;
        }
    }

    static setTooltipHasBeenShown(tooltipKey: string, hasBeenShown: boolean): void {
        try {
            localStorage.setItem(tooltipKey, `${hasBeenShown}`);
        } catch (e) {
            return;
        }
    }

}
