<template>
  <div v-on:click="toggleOpen" v-on:click-outside="close" id="sort-by-container">
    <div :class="{ isFocused: isOpen }" class="box unselectable">
      <p>{{ sortOrderToText(selectedSortOrder) }}</p>
      <img src="../assets/arrow-down-filled.svg" class="arrow" :class="{ rotated: isOpen }" alt="">
    </div>
    <div v-if="isOpen" class="drop-down">
      <div class="spacer"></div>
      <div v-for="sortOrder in sortOrders" :key="sortOrder" v-on:click="selectSortOrder(sortOrder)"
           class="drop-down-cell">
        <div v-if="sortOrder===selectedSortOrder" class="selected drop-down-cell">
          <p>{{ sortOrderToText(sortOrder) }}</p>
        </div>
        <p v-else>{{ sortOrderToText(sortOrder) }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {SortOrder} from "@/Application/model/SortOrder";
import {SortOrderText} from "@/Application/model/SortOrderText";

export default defineComponent({
  name: "SortBy",
  mounted() {
    window.addEventListener('click', (e) => {
      if (!this.$el.contains(e.target)) {
        this.isOpen = false
      }
    })
  },
  methods: {
    toggleOpen() {
      this.isOpen = !this.isOpen;
    },
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    selectSortOrder(newSortOrder: SortOrder) {
      if (this.selectedSortOrder === newSortOrder) {
        this.isOpen = false;
        return
      }

      this.selectedSortOrder = newSortOrder;
      this.isOpen = false;
      this.$emit('sortOrderChanged', newSortOrder)
    },
    sortOrderToText(sortOrder: SortOrder) {
      return SortOrderText[sortOrder]
    },
  },
  data() {
    return {
      isOpen: false,
      selectedSortOrder: this.$props.preSelectedSortOrder,
      sortOrders: [SortOrder.mostHits, SortOrder.newest, SortOrder.oldest, SortOrder.nameAscending, SortOrder.nameDescending],
    }
  },
  props: {
    preSelectedSortOrder: {
      type: String as PropType<SortOrder>,
      required: true
    }
  },
});

</script>

<style scoped>

#sort-by-container {
  width: 150px;
  --computer-arrow-padding: 15px;
  --mobile-arrow-padding: 11px;
}

.box p {
  font-size: min(0.9em, 3.7vw);
  margin: 0;
  vertical-align: center;
  align-items: center;
  padding-left: 15px;
  text-align: left;
  display: inline-block;
  float: left;
}

.box {
  display: flex;
  background-color: white;
  width: inherit;
  cursor: pointer;
  border: 1px rgba(0, 0, 0, 0.3) solid;
  vertical-align: center;
  align-items: center;
  justify-content: space-between;
  padding: 3px 0;
  border-radius: 4px;
  height: 32px;
}

.spacer {
  width: inherit;
  height: 5px;
}

.isFocused {
  box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.3) inset;
}

.arrow {
  width: 12px;
  height: auto;
  padding-right: var(--computer-arrow-padding);
  horiz-align: right;
  float: right;
}

.rotated {
  transform: rotate(180deg);
  padding-left: var(--computer-arrow-padding);
  padding-right: 0;
}

.drop-down {
  position: absolute;
  transform: translateX(2px);
  height: auto;
  background-color: white;
  width: 148px;
  cursor: pointer;
  border-radius: 2px;
  box-shadow: 0 3px 3px 3px rgba(19, 15, 15, 0.2);
}

.drop-down-cell {
  overflow: auto;
  height: 38px;
}

.drop-down-cell:hover {
  background-color: rgba(14, 13, 19, 0.03);
}

.drop-down-cell:first-of-type {
}

.drop-down-cell p {
  font-size: min(0.85em, 3.7vw);
  margin: 0;
  padding: 11px min(3px, 1vw) min(3px, 1vw) min(15px, 3vw);
  text-align: left;
  display: inline-block;
  float: left;
}

.selected {
  background-color: #F1F1F1;
}

.drop-down-cell:last-of-type {
  border-bottom: none;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (max-width: 46rem) {
  p {
    font-size: min(1em, 3.7vw);
  }
}

@media (max-width: 450px) {

  .box {
    height: 27px;
  }

  #sort-by-container {
    width: 105px;
  }

  .box p {
    font-size: 0.75rem;
    padding-left: 12px;
  }

  .drop-down {
    width: 105px;
  }

  .drop-down-cell p {
    font-size: 0.75rem;
  }

  .arrow {
    padding-right: var(--mobile-arrow-padding);
    width: 10px;
  }

  .rotated {
    padding-right: 0;
    padding-left: var(--mobile-arrow-padding);
  }

}


</style>
