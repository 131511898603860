<template>
    <section
        class="clients section"
        :class="[
            topOuterDivider && 'has-top-divider',
            bottomOuterDivider && 'has-bottom-divider',
            hasBgColor && 'has-bg-color',
            invertColor && 'invert-color'
        ]">
        <div class="container">
            <div
                class="clients-inner section-inner"
                :class="[
                    topDivider && 'has-top-divider',
                    bottomDivider && 'has-bottom-divider'
                ]">
                <ul class="list-reset">
                    <li data-aos="fadeUp">
                        <c-image
                            :src="require('/src/LandingPage/assets/images/perfect-day-media-grey.png')"
                            alt="Client 01"
                            :width="130"
                            :height="24" />
                    </li>
                    <li data-aos="fadeUp" data-aos-delay="200">
                        <c-image
                            :src="require('/src/LandingPage/assets/images/aos.jpg')"
                            alt="Client 02"
                            :width="60"
                            :height="30" />
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
import { SectionProps } from '/src/LandingPage/utils/SectionProps.js'
import CImage from '/src/LandingPage/components/elements/Image.vue'

export default {
  name: 'CClients',
  components: {
    CImage
  },
  mixins: [SectionProps]
}
</script>
