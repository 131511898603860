<template>
  <div id="tipDiv" >
     <p id="textTipInInfobox" >
      För många sökträffar? Söka efter citat? Omslut sökorden inom <span class="bold"> citattecken (" ") </span>.
     </p>
    <img class="hidebox-button close-img" src="@/Application/assets/close.png" />
  </div>
</template>

<script>
export default {
  name: "TipBox",
  methods: {
  }
}
</script>

<style scoped>

.close-img {
  display: inline-block;
  height: 17px;
  cursor: pointer;
  margin: auto;
  margin-left: 1em;
  opacity: 1
}

.close-img:hover {
  opacity: 0.5;
}

#tipDiv {
  display: flex;
  margin-top: 0.5rem;
  transition: 0.4s linear;
  position: absolute;
  border: 0.5px solid rgba(14, 13, 19, 0.3);
  padding: min(1rem, 2vh);
  padding-left: 1rem;
  padding-right: 0.9rem;
  background-color: white;
  z-index: 100;
  width: fit-content;
  max-width: 75%;
  margin-left: 5.5em;
  /* COMPUTER */
  /*box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;*/

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  border-radius: 1rem;
}

#textTipInInfobox {
  margin: auto;
  display: inline-block;
  font-size: 0.8rem;
}


.bold {
  font-weight: bold;
}

@media (max-width: 600px) {
  #tipDiv {
    text-align: left;
    padding-left: 1.5em;
    margin: 1.5rem auto auto 0.6em;
  }

  #textTipInInfobox {
    font-size: 0.75rem;
  }

  .close-img {
    margin-left: 0.5em;
  }

}

</style>