<template>
    <section
        class="hero section center-content"
        :class="[
            topOuterDivider && 'has-top-divider',
            bottomOuterDivider && 'has-bottom-divider',
            hasBgColor && 'has-bg-color',
            invertColor && 'invert-color'
        ]">
        <div class="container-sm">
            <div
                class="hero-inner section-inner"
                :class="[
                    topDivider && 'has-top-divider',
                    bottomDivider && 'has-bottom-divider'
                ]">
                <div class="hero-content">
                  <h1 id="first-title" data-aos="fadeDown" data-aos-delay="200" class="mt-80 mb-16">
                      Tillgängliggör din podcast!
                    </h1>

                    <div class="container-xs">
                        <p data-aos="fadeDown" data-aos-delay="350" class="m-0 mb-32">
                          Är du en podcastkreatör och vill öka värdet för dig och dina lyssnare? Låt dina lyssnare söka i din podcast och hitta tillbaka till sina favoritsegment!                   </p>
                        <div>
                            <!--<c-button tag="a" color="primary" href="mailto:info@pearch.se" target="_blank">
                                Kontakta oss
                            </c-button> -->
                        </div>
                    </div>
                </div>
                <div data-aos="fadeUp" data-aos-delay="600" class="hero-figure">
                    <c-image
                        class="has-shadow"
                        :src="require('/src/LandingPage/assets/images/pearch-screenshot-aos.png')"
                        alt="Hero"
                        :width="600"
                        :height="300" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { SectionProps } from '/src/LandingPage/utils/SectionProps.js'
import CImage from '/src/LandingPage/components/elements/Image.vue'

export default {
  name: 'CHeroFull',
  mixins: [SectionProps],
  components: {
    CImage
  }
}
</script>
