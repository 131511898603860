<template>
  <div class="tooltip-popup">
    <h2>{{ title }}</h2>
    <p>{{ message }}</p>
    <button @click="$emit('close')">Okej</button>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: "TooltipPopup",
  props: {
    title: String,
    message: String
  },
  methods: {
    close() {

    }
  }
})
</script>

<style scoped>

.tooltip-popup {
  max-width: 190px;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  position: absolute;
  isolation: isolate;
  opacity: 1;
}

h2 {
  text-align: left;
  font-size: 16px;
  margin: 0 0 5px 0;
}

p {
  text-align: left;
  font-size: 14px;
  margin: 10px 0px 5px 0px;
}

button {
  border-radius: 10px;
  padding: 4px 15px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.10);
  border-width: 1px;
  float: right;
  background-color: rgba(63, 58, 58, 0.02);
}

button:hover {
  cursor: pointer;
  border-color: rgba(0, 0, 0, 0.35);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset;
}

@media(max-width: 500px) {
  .tooltip-popup {
    max-width: 160px;
    padding: 16px;
  }

  h2 {
    font-size: 14px;
    margin: 0 0 5px 0;
  }

  p {
    text-align: left;
    font-size: 12px;
    margin: 2px 0px 2px 0px;
  }

  button {
    border-radius: 10px;
    padding: 3px 12px;
    font-size: 12px;
    background-color: rgba(63, 58, 58, 0.02);
  }
}

</style>
