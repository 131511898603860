<template>
  <div v-on:click="hideTipBox()" class="container">
    <AoSWidgetTopSearchbar :page="$options.name" class="topSearchBar"/>
    <QueryResults class="query-results"/>
  </div>
  <div id="mobileDiv"></div>
</template>

<script>
import QueryResults from "@/Application/components/QueryResults/QueryResults";
import AoSWidgetTopSearchbar from "@/Application/components/AoSWidgetTopSearchbar";
import InsightHandler from "@/sharedAssets/statistics/InsightHandler";
import {Podcast} from "@/Application/model/Podcast";
import {EnvironmentUrl} from "@/Application/model/enums/EnvironmentUrl";

export default {
  name: "AoSWidgetPage",
  components: {AoSWidgetTopSearchbar, QueryResults},
  created() {
    this.redirectUser();
  },
  mounted() {
    InsightHandler.pageViewInsight(Podcast.Alexosigge, this.$options.name, this.isMobile());
  },
  methods: {
    isMobile() {
      return getComputedStyle(document.getElementById("mobileDiv")).color === "rgba(17, 51, 51, 0.467)";
    },
    hideTipBox() {
      // @ts-ignore
      if (document.getElementsByClassName("tipbox")[0].style.opacity !== 0) {
        // @ts-ignore
        document.getElementsByClassName("tipbox")[0].style.opacity = 0
      }
    },
    redirectUser() {
      if (!this.isIframe()) {
        if (window.location.origin === EnvironmentUrl.PROD_ENV_URL) {
          this.$router.push({path: '/ '});
        }
      }
    },
    isIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
  }
}
</script>

<style scoped>

.topSearchBar {
  /*margin-top: min(1rem, 2vw) !important;*/
  margin-bottom: 2rem !important;
}

.query-results {
  max-width: 46rem;
  margin: 0 auto;
}

#mobileDiv {
  display: none;
  color: black;
}

@media (hover: none) {
  #mobileDiv {
    color: #1337;
  }
}


</style>
