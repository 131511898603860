
import {Options, Vue} from 'vue-class-component';
import {v4 as uuidv4} from 'uuid';
import {WebStorage} from "@/sharedAssets/model/enums/WebStorage";
import TooltipContainer from "@/Application/components/tooltip/TooltipContainer.vue";

@Options({
  components: {TooltipContainer}
})
export default class App extends Vue {
  mounted() {
    this.setSessionId();
    this.setUserId()
  }

  setSessionId() {
    try {
      if (!sessionStorage.getItem(WebStorage.SESSION_ID)) {
        sessionStorage.setItem(WebStorage.SESSION_ID, uuidv4());
      }
    } catch (e) {
      console.log(e)
    }
  }

  setUserId() {
    try {
      if (!localStorage.getItem(WebStorage.USER_ID)) {
        localStorage.setItem(WebStorage.USER_ID, uuidv4());
      }
    } catch (e) {
      console.log(e)
    }
  }
}
