<template>
  <div class="header" v-on:click="open">
    <div class="header-left">

      <div class="title-text semi-bold">
        <p class="avsnitt-title"> {{ episodeNumber + ".  " + getFormattedEpisodeTitle(episodeName) }}</p>
        <b class="publish-data"> <span class="publish-date">{{ getFormattedPublishDate(episodePublishDate) }}</span>
          <span class="bullet-point"> &#8226; </span> <span
              class="episode-length">{{ getFormattedDate(episodeDuration) }}</span></b>
      </div>

      <div class="platform-buttons-mobile-container">
        <PlatformPlayButton :streamingPlatform="'Spotify'" :url="spotifyUrl" :episode-name="episodeName"
                            :episode-number="episodeNumber" :query="query"></PlatformPlayButton>
        <PlatformPlayButton :streamingPlatform="'Apple'" :url="appleUrl" :episode-name="episodeName"
                            :episode-number="episodeNumber" :query="query"></PlatformPlayButton>
      </div>
    </div>

    <div class="header-right">
      <div class="platform-buttons-desktop-container">
        <PlatformPlayButton :streamingPlatform="'Spotify'" :url="spotifyUrl" :episode-name="episodeName"
                            :episode-number="episodeNumber" :query="query"></PlatformPlayButton>
        <PlatformPlayButton :streamingPlatform="'Apple'" :url="appleUrl" :episode-name="episodeName"
                            :episode-number="episodeNumber" :query="query"></PlatformPlayButton>
      </div>

      <div class="header-right-inner-container semi-bold">
        <p class="num-hits unselectable" v-if="numHits > max_hit_in_episode">{{
            "> " + max_hit_in_episode + " träffar"
          }}</p>
        <p class="num-hits unselectable" v-else-if="numHits == 1">{{ numHits + " träff" }}</p>
        <p class="num-hits unselectable" v-else>{{ numHits + " träffar" }}</p>
        <div class="collapse-arrow" v-bind:class="{open:isOpen}"/>
      </div>
    </div>

  </div>

</template>

<script lang="ts">
import {DateUtil} from "@/Application/util/DateUtil";
import {defineComponent} from 'vue';
import PlatformPlayButton from "@/Application/components/QueryResults/PlatformPlayButton.vue";

export default defineComponent({
  name: "EpisodeHeader",
  components: {PlatformPlayButton},
  props: {
    query: String, // TODO Kanske sätta centralt?
    episodeNumber: Number,
    episodeName: String,
    episodePublishDate: String,
    episodeDuration: String,
    numHits: Number,
    spotifyUrl: String,
    appleUrl: String,
    max_hit_in_episode: Number
  },
  data() {
    return {
      isOpen: false
    }
  },
  mounted() {
    this.changeAndroidFont();
  },
  methods: {
    open() {
      if (this.$props.numHits) {
        if (this.$props.numHits > 0) {
          this.isOpen = !this.isOpen;
        }
      }
    },
    getFormattedDate(dateString: string) {
      return DateUtil.getFormattedDate(dateString)
    },
    getFormattedPublishDate(publishDate: string) {
      return DateUtil.getFormattedPublishDate(publishDate)
    },
    getFormattedEpisodeTitle(episodeTitle: string) {
      if (episodeTitle.length > 42 && window.matchMedia("(max-width: 700px)").matches) {
        return episodeTitle.slice(0, 42) + "..."
      }
      return episodeTitle
    },
    changeAndroidFont() {
      if (navigator.userAgent.match(/Android/i)) {
        let publishData = document.getElementsByClassName("publish-data");
        let numHits = document.getElementsByClassName("num-hits");
        for (let i = 0; i < publishData.length; i++) {
          //@ts-ignore
          publishData[i].style.letterSpacing = 0;
          //@ts-ignore
          numHits[i].style.letterSpacing = 0;
        }
      }
    }
  },
});
</script>

<style scoped>

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.semi-bold {
  font-family: NunitoSemiBold, serif;
}

.header {
  padding: min(1.5rem, max(6vw, 5px)) 0.65vw;
  display: flex;
  background-color: transparent;
  transition: background-color 0.05s linear;
  height: min(4.5rem, 15vw);
  vertical-align: center;
  align-items: center;
}

.header:hover {
  background-color: rgba(14, 13, 19, 0.03);
}

.header-left {
  text-align: left;
  display: flex;
  flex-direction: column;
  width: fit-content;
  vertical-align: middle;
  horiz-align: left;
  max-width: 55%;
}

.title-text {
  color: rgb(52, 44, 44);
  font-size: min(1.15em, 3.7vw);
}

.title-text p {
  margin: 0;
  font-weight: 500;
}

.title-text b {
  font-weight: 700;
}

.publish-data {
  display: inline-block;
  font-size: min(0.7em, 3.5vw);
  padding-top: min(0.5rem, 1vw);
  width: 100%;
}

.publish-date {
  margin-right: 0.2em;
}

.episode-length {
  margin-left: 0.2em;
}


.num-hits:hover {
  text-decoration: underline;
  cursor: pointer;
}

.header-right {
  display: flex;
  margin: auto 0 auto auto;
  vertical-align: center;
  horiz-align: right;
  align-items: center;
  width: 40%;
}

.header-right p {
  display: inline-block;
  text-align: center;
  margin: auto auto auto 0;
  vertical-align: center;
}

.platform-buttons-desktop-container {
  display: inline-flex;
  width: 160px;
  justify-content: space-between;
  float: left;
}

.platform-buttons-mobile-container {
  display: none;
}

.collapse-arrow {
  border: solid black;
  border-width: 0 0.18rem 0.18rem 0;
  display: inline-block;
  width: min(0.6rem, 1.5vw);
  height: min(0.6rem, 1.5vw);
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  opacity: 0.6;
  cursor: pointer;
  margin-left: 25px;
}

.bullet-point {
  font-family: serif !important;
}

.open {
  transform: rotate(45deg) translateX(-5px);
}

.header-right-inner-container {
  display: flex;
  text-align: center;
  align-items: center;
  margin: auto 0 auto auto;
  width: auto;
}

.num-hits {
  font-size: min(0.9rem, max(2.6vw, 7px));
}


@media (max-width: 46rem) {
  .header-left {
    margin-left: 1vw;
  }

  .header-right {
    margin-right: 1vw;
  }

  .collapse-arrow {
    border-width: 0 0.15rem 0.15rem 0;
  }

  .header-left {
    max-width: 70%;
  }

  .open {
    transform: rotate(45deg) translateX(-2px);
  }

  .header-right {
    width: 30%;
  }

  .num-hits {
    font-family: NunitoBold, serif;
  }
}


@media (max-width: 600px) {
  .publish-data, .num-hits {
    letter-spacing: -1px;
  }

  .num-hits {
    font-size: 0.85em;
  }

  .avsnitt-title {
    font-family: NunitoBold, serif;
    color: rgba(0, 0, 0, 0.75);
    font-size: 17px;
  }

  .publish-data {
    font-size: 0.8em;
  }

  .header-right {
    width: 30%;
  }

  .collapse-arrow {
    margin-left: 15px;
    width: 7px;
    height: 7px;
    border-width: 0 0.15rem 0.15rem 0;
  }

  .platform-buttons-mobile-container {
    margin-top: 6%;
    display: inline-flex;
    width: 128px;
    justify-content: space-between;
    float: left;
  }

  .platform-buttons-desktop-container {
    display: none;
  }

  .header {
    height: 72px;
  }
}

</style>
