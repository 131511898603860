<template>
    <header class="site-header mt-8" :class="bottomOuterDivider && 'has-bottom-divider'">
        <div class="container">
            <div class="site-header-inner" :class="bottomDivider && 'has-bottom-divider'">
                <c-logo />
                <button
                    v-if="!hideNav"
                    ref="hamburger"
                    class="header-nav-toggle"
                    aria-controls="primary-menu"
                    :aria-expanded="isActive ? 'true' : 'false'"
                    @click="isActive ? closeMenu() : openMenu()">
                    <span class="screen-reader">Menu</span>
                    <span class="hamburger">
                        <span class="hamburger-inner"></span>
                    </span>
                </button>
                <nav
                    v-if="!hideNav"
                    ref="nav"
                    class="header-nav"
                    :class="{ 'is-active': isActive }">
                    <div class="header-nav-inner">
                        <ul
                            class="list-reset text-xxs"
                            :class="navPosition && `header-nav-${navPosition}`">
                            <li>
                                <a style="cursor: pointer; font-weight: bold; text-decoration: none;" v-on:click="scrollToTeam(); omOssButtonClickInsight()">Om oss</a>
                            </li>
                        </ul>
                        <ul v-if="!hideSignin" class="list-reset header-nav-right">
                            <li>
                                <a v-on:click="upperKontaktButtonClickInsight()" href="mailto:hello@pearch.se" target="_blank" class="button button-primary button-wide-mobile button-sm">Kontakt</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    </header>
</template>

<script>
import CLogo from '/src/LandingPage/components/layout/partials/Logo.vue'
import {Button} from "@/LandingPage/model/Button";
import InsightHandler from "@/sharedAssets/statistics/InsightHandler.ts";

export default {
  name: 'CHeader',
  components: {
    CLogo
  },
  props: {
    page: String,
    active: Boolean,
    navPosition: {
      type: String,
      default: ''
    },
    hideNav: {
      type: Boolean,
      default: false
    },
    hideSignin: {
      type: Boolean,
      default: false
    },
    bottomOuterDivider: {
      type: Boolean,
      default: false
    },    
    bottomDivider: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: this.active || false
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  methods: {
    omOssButtonClickInsight() {
      InsightHandler.buttonClickInLandingPageInsight(this.$props.page, Button.OmOss);
    },
    upperKontaktButtonClickInsight() {
      InsightHandler.buttonClickInLandingPageInsight(this.$props.page, Button.UpperKontakt);
    },
    openMenu() {
      document.body.classList.add('off-nav-is-active')
      if (this.$refs.nav)
        this.$refs.nav.style.maxHeight = this.$refs.nav.scrollHeight + 'px'
      this.$emit('update:active', true)
      this.isActive = true
    },
    scrollToTeam() {
      document.getElementById("team").scrollIntoView({
        behavior: "smooth"
      });
    },
    closeMenu() {
      document.body.classList.remove('off-nav-is-active')
      if (this.$refs.nav) this.$refs.nav.style.maxHeight = null
      this.$emit('close')
      this.$emit('update:active', false)
      this.isActive = false
    },
    keyPress() {
      this.isActive && event.keyCode === 27 && this.closeMenu()
    },
    clickOutside(e) {
      if (!this.$refs.nav) return
      if (
        !this.isActive ||
        this.$refs.nav.contains(e.target) ||
        e.target === this.$refs.hamburger
      )
        return
      this.closeMenu()
    }
  },
  mounted() {
    this.active && this.openMenu()
    document.addEventListener('keydown', this.keyPress)
    document.addEventListener('click', this.clickOutside)
  },
  beforeUnmount() {
    document.addEventListener('keydown', this.keyPress)
    document.removeEventListener('click', this.clickOutside)
    this.closeMenu()
  }
}
</script>