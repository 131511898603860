<template>
    <section
        class="section"
        :class="[
            topOuterDivider && 'has-top-divider',
            bottomOuterDivider && 'has-bottom-divider',
            hasBgColor && 'has-bg-color',
            invertColor && 'invert-color'
        ]">

        <div class="container center-content">
            <div
                class="section-inner"
                :class="[
                    topDivider && 'has-top-divider',
                    bottomDivider && 'has-bottom-divider'
                ]">
              <c-section-header :data="sectionHeader" class="center-content"/>

              <a v-on:click="lowerButtonClickInsight()"
                  style="text-decoration: none;"
                 href="mailto:hello@pearch.se"
                 target="_blank">
                    <c-button
                        data-aos="fadeUp"
                        color="primary">
                        Kontakta oss
                    </c-button>
              </a>
            </div>
        </div>
    </section>
</template>

<script>
import {SectionTilesProps} from '/src/LandingPage/utils/SectionProps.js'
import CSectionHeader from "/src/LandingPage/sections/partials/SectionHeader.vue";
import CButton from "/src/LandingPage/components/elements/Button.vue";
import {Button} from "@/LandingPage/model/Button";
import InsightHandler from "@/sharedAssets/statistics/InsightHandler";

export default {
  name: 'CGenericSection',
  components: {CButton, CSectionHeader},
  mixins: [SectionTilesProps],
  props: {
    page: String,
  },
  data() {
    return {
      sectionHeader: {
        title: 'Podcastkreatör eller lyssnare?',
        paragraph:
            'Vill ni också göra er podcast sökbar på er hemsida? Vill du att din ' +
            'favorit-podd ska bli sökbar? Släng iväg ett mejl till oss!'
      }
    }
  },
  methods: {
    lowerButtonClickInsight() {
      InsightHandler.buttonClickInLandingPageInsight(this.$props.page, Button.LowerKontakt);
    },
  }
}
</script>