<template>
  <div>
    <div v-on:mouseleave="hideInfoBoxOnDelay" id="searchDiv">
      <p id="title">Sök i podden</p>
      <p id="infoText">
        Hitta tillbaka till dina favoritavsnitt - eller upptäck nya! Sök i poddens alla avsnitt efter ämnen, specifika
        ord eller citat. Klicka för att lyssna på avsnittet på Spotify eller Apple Podcasts.
      </p>
      <input id="searchBar" enterkeyhint="search" type="text" v-model="query" @keyup.enter="scrollUp(); search(true)"
             placeholder="Sök">
      <button id="searchButton" v-on:click="search(true)">Sök</button>
      <Samarbete :page="$options.name" style="margin-left: 0.8rem; margin-right: 1.5rem"
                 v-on:mouseleave="hideInfoBoxOnDelay" @isShowInfoBox="onCLickChild"/>
      <Transition name="fade">
        <InfoBox :page="$options.name" class="infobox" v-on:mouseenter="setMouseOnInfoBox"
                 v-on:mouseleave="removeMouseOnInfoBox" v-if="showInfoBox"/>
      </Transition>
    </div>
  </div>
  <div id="mobileDiv"></div>
</template>

<script>
import InfoBox from "@/Application/components/InfoBox";
import Samarbete from "@/Application/components/Samarbete";
import InsightHandler from "@/sharedAssets/statistics/InsightHandler";
import {Podcast} from "@/Application/model/Podcast";
import {EnvironmentUrl} from "@/Application/model/enums/EnvironmentUrl";
import {SortOrder} from "@/Application/model/SortOrder";

export default {
  name: "AoSWidgetStandalone",
  components: {Samarbete, InfoBox},
  data() {
    return {
      query: '',
      pageName: "AoSWidgetStandalone",
      showInfoBox: false,
    };
  },
  methods: {
    scrollUp() {
      window.scrollTo(0, -100);
    },
    search() {
      // bugfix for ios not scrolling up when keyboard goes down.
      // important that it is inside a timeout
      if (this.query.length > 0) {
        setTimeout(() => {
          this.$router.push({path: '/widget/aos-search',
            query: {q: this.query, sort: SortOrder.mostHits}}
          );
        }, 0.001);
      }
    },
    onCLickChild(value) {
      this.showInfoBox = value;
      if (this.showInfoBox) {
        document.getElementById("info").style.opacity = "1";
      } else {
        document.getElementById("info").style.opacity = "0.6";
      }
    },
    hideInfoBox() {
      if (!this.isMouseOnInfoBox) {
        this.showInfoBox = false;
        document.getElementById("info").style.opacity = "0.6";
      }
    },
    setMouseOnInfoBox() {
      this.isMouseOnInfoBox = true;
    },
    removeMouseOnInfoBox() {
      this.isMouseOnInfoBox = false;
    },
    hideInfoBoxOnDelay() {
      setTimeout(this.hideInfoBox, 250);
    },
    isMobile() {
      return getComputedStyle(document.getElementById("mobileDiv")).color === "rgba(17, 51, 51, 0.467)";
    },
    redirectUser() {
      if (!this.isIframe()) {
        if (window.location.origin === EnvironmentUrl.PROD_ENV_URL) {
          this.$router.push({path: '/ '});
        }
      }
    },
    isIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
  },
  created() {
    this.redirectUser()
  },
  mounted() {
    InsightHandler.pageViewInsight(Podcast.Alexosigge, this.$options.name, this.isMobile());
  },
}
</script>

<style scoped>

#searchDiv {
  margin: auto;
  text-align: center;
  width: min(36rem, 90%);
  margin-top: 6rem;
}

#title {
  color: hsl(18, 100%, 55%);
  margin-bottom: 2rem;
  display: block;
  font-size: 3rem;
  font-family: Poppins, serif;
}

#infoText {
  line-height: 1.7;
  width: min(35rem, 95%);
  font-size: 1.15rem;
  margin: auto auto 4rem;
  text-align: center;
}

#searchBar {
  display: inline;
  margin: auto;
  height: 50px;
  width: min(25rem, 80%);
  padding-left: max(7.5%, 50px);
  border-radius: 100px;
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-width: 0px;
  font-size: 90%;
  background-image: url('../../../assets/searchIcon.png');
  background-repeat: no-repeat;
  background-size: 1.2rem;
  background-position: max(3.5%, 20px) 50%;
  margin-bottom: 0.2rem;
}

#searchButton {
  margin-left: 2rem;
  display: inline;
  height: 53px;
  width: 4.5rem;
  border-radius: 100px;
  outline: none;
  background-image: linear-gradient(hsl(24, 100%, 55%), hsl(12, 100%, 55%));
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-width: 0px;
  font-size: 0.95rem;
  color: white;
  cursor: pointer;
}

#searchButton:hover {
  background-image: linear-gradient(hsl(22, 100%, 55%), hsl(22, 100%, 55%));
}

.infobox {
  width: 22rem !important;
  text-align: left;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.fade-enter-to, .fade-leave-from {
  opacity: 1;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.4s ease !important;
}


@media (max-width: 650px) {

  #searchBar {
    width: 65%;
  }

  #title {
    font-size: 2.5rem;
  }

  #infoText {
    font-size: 1.1rem;
  }


}

@media (max-width: 500px) {

  #searchBar {
    width: 80%;
  }

  #searchButton {
    display: none;
  }

  #infoText {
    font-size: 1rem;
  }

  #infoText {
    margin-bottom: 3rem;
  }

  #searchDiv {
    margin-top: 2rem;
  }


}

#mobileDiv {
  display: none;
  color: black;
}

@media (hover: none) {
  #mobileDiv {
    color: #1337;
  }
}

</style>
