<template>
  <div class="query-result-list-row">
    <EpisodeHeader
        :episode-name="result.title"
        :episode-number="result.number"
        :episode-publish-date="result.rssPublishDate"
        :episode-duration="result.rssDuration"
        :num-hits="result.numHits"
        :spotify-url="result.spotifyUrl"
        :apple-url="result.appleUrl"
        :max_hit_in_episode="max_hit_in_episode"
        :query = "query"
        v-on:click="open(); episodeClickInsight();"
    />

    <div v-if="isOpen" class="episode-inside-divider"/>

    <EpisodeHitList
        v-if="isOpen"
        class="episode-query-result-list"
        :query="query"
        :episode-name="result.title"
        :episode-number="result.number"
        :hits="result.hits"
        :spotify-url="result.spotifyUrl"
    />

    <div class="episode-divider"/>
  </div>

</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import EpisodeHeader from "@/Application/components/QueryResults/EpisodeHeader.vue";
import EpisodeHitList from "@/Application/components/QueryResults/EpisodeHitList.vue";
import { EpisodeQueryResult } from "@/Application/model/EpisodeQueryResult";
import InsightHandler from "@/sharedAssets/statistics/InsightHandler";
import {Podcast} from "@/Application/model/Podcast";
import TooltipManager from "@/Application/components/tooltip/TooltipManager";


export default defineComponent({
  name: "Episode",
  components: {EpisodeHitList, EpisodeHeader },
  props: {
    query: String,
    result: Object as PropType<EpisodeQueryResult>,
    max_hit_in_episode: Number
    // wordsToHighlight: Array as PropType<string[]>
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    open() {
      if (this.$props.result)
        if (this.$props.result.numHits > 0) {
        this.isOpen = !this.isOpen;
      }

      if (this.isOpen) {
        TooltipManager.instance().show('tooltip_episodeTimeStamp', '.timestamp-play-button', 300);
      }
    },
    episodeClickInsight() {
      if (this.$props.result) {
        if (this.isOpen) {
          InsightHandler.episodeClickInsight(Podcast.Alexosigge, this.$props.query, this.$props.result.number, this.$props.result.title)
        }
      }
    }
  }
})
</script>

<style scoped>

.query-result-list-row {
}

.episode-query-result-list {
  width: 100%;
  height: 100%;
}

.episode-divider {
  width: 100%;
  height: 1px;
  background-color: black;
  opacity: 0.25;
}

.episode-inside-divider {
  height: 1px;
  background-color: black;
  opacity: 0.25;
}

</style>
