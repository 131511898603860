<template>
    <section
        ref="team"
        class="team section center-content"
        :class="[
            topOuterDivider && 'has-top-divider',
            bottomOuterDivider && 'has-bottom-divider',
            hasBgColor && 'has-bg-color',
            invertColor && 'invert-color'
        ]">
        <div class="container">
            <div
                class="team-inner section-inner pt-0"
                :class="[
                    topDivider && 'has-top-divider',
                    bottomDivider && 'has-bottom-divider'
                ]">
              <br> <br>
                <c-section-header :data="sectionHeader" class="center-content" style="padding-bottom: 0 !important;" />
                <div
                    class="tiles-wrap"
                    :class="[
                        pushLeft && 'push-left',
                    ]">
<!--                    <div data-aos="fadeUp" class="tiles-item">-->
<!--                        <div class="tiles-item-inner">-->
<!--                            <div class="team-item-header">-->
<!--                                <div class="team-item-image mb-24">-->
<!--&lt;!&ndash;                                    <c-image&ndash;&gt;-->
<!--&lt;!&ndash;                                        :src="require('/src/LandingPage/assets/images/bjorn2-cropped.jpg')"&ndash;&gt;-->
<!--&lt;!&ndash;                                        alt="Team member 01"&ndash;&gt;-->
<!--&lt;!&ndash;                                        :width= 180&ndash;&gt;-->
<!--&lt;!&ndash;                                        :height="180" />&ndash;&gt;-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="team-item-content">-->
<!--                                <h5 class="team-item-name mt-0 mb-0">-->
<!--                                    Björn Hickman-->
<!--                                </h5>-->
<!--                                <div class="team-item-role text-xs fw-500 mb-8">-->
<!--                                </div>-->
<!--                                <p class="m-0 text-sm">-->
<!--                                </p>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->

                    <div class="tiles-item">
                        <div class="tiles-item-inner">
                            <div class="team-item-header">
                                <div class="team-item-image mb-24">
                                    <!--<c-image
                                        :src="require('/src/LandingPage/assets/images/team-member-02.jpg')"
                                        alt="Team member 02"
                                        :width="138"
                                        :height="180" /> -->
                                </div>
                            </div>
                            <div class="team-item-content center-content">
                              <div>
                                <p style="font-weight: bold" class="team-item-name mt-0 mb-0">
                                  Björn Hickman <br> & Victor Holmqvist
                                </p>

                              </div>
                                <div class="team-item-role text-xs fw-500 mb-8">
                                </div>
                                <p class="m-0 text-sm">
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { SectionTilesProps } from '/src/LandingPage/utils/SectionProps.js'
import CSectionHeader from '/src/LandingPage/sections/partials/SectionHeader.vue'
/*import CImage from '/src/LandingPage/components/elements/Image.vue'*/

export default {
  name: 'CTeam',
  components: {
    CSectionHeader,
    /*CImage*/
  },
  mixins: [SectionTilesProps],
  data() {
    return {
      sectionHeader: {
        title: 'Vilka är vi?',
        paragraph:
        'Vi är två studenter från KTH som står bakom Pearch. Vi båda konsumerar podcasts i stor utsträckning och insåg hur svårt det' +
            ' är att hitta i det lyssnade materialet. Varje gång vi ville visa ett av våra favoritsegment för nära och ' +
            'kära slutade det oftast med att vi istället fick återberätta segmentet.'+
            ' Med vårt intresse för programmering satte vi igång och försökte lösa problemet. ' +
            'Pearch är för närvarande under utveckling och vi uppskattar all möjlig feedback! '
      }
    }
  }
}
</script>