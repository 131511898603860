<template>
  <a v-on:click="logTimeStampClick" v-bind:href="url" target="_blank">
    <div class="container unselectable timestamp-play-button">
      <p class="text-container" >
        {{time}}
      </p>

      <div  class="image-container">
        <img class="spotify-logo logo"
             src="@/Application/assets/logos/Spotify_Logo.png" alt="">
      </div>


    </div>
  </a>
</template>

<script>
export default {
  name: "TimeStampPlayButton",
  props: {
    logTimeStampClick: Function,
    time: String,
    url: String
  }
}
</script>

<style scoped>

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a {
  text-decoration: none;
  color: initial;
}

.logo {
  height: 12px;
}

.image-container {
  display: inherit;
  align-content: center;
  margin: auto auto auto 0;
}

.text-container {
  font-size: 12px;
  margin: auto;
  padding-left: 5%;
}

.semi-bold {
  font-family: NunitoSemiBold, serif;
}

.container {
  display: inline-flex;
  width: 71px;
  height: 23px;
  border-style: solid;
  background-color: rgba(63, 58, 58, 0.02);
  border-color: rgba(0, 0, 0, 0.06);
  border-width: 1px;
  border-radius: 30px;
}

.container:hover {
  cursor: pointer;
  border-color: rgba(0, 0, 0, 0.35);
  box-shadow:0 0 0 1px rgba(0, 0, 0, 0.15) inset;
}

@media (max-width: 450px) {

  .container {
    width: 62px;
    height: 20px;
  }

  .text-container {
    font-size: 11px;
  }

  .logo {
    height: 11px;
  }

}


</style>
