
import {defineComponent, PropType} from 'vue';
import HitInEpisode from "@/Application/components/QueryResults/HitInEpisode.vue";
import { QueryHit } from '@/Application/model/QueryHit';

export default defineComponent({
  name: "EpisodeHitList",
  components: { HitInEpisode },
  props: {
    query: String,
    episodeName: String,
    episodeNumber: Number,
    spotifyUrl: String,
    hits: Array as PropType<QueryHit[]>
  }
});

