
import {defineComponent} from "vue";
import Tooltip from "@/Application/components/tooltip/TooltipPopup.vue";
import TooltipManager from "@/Application/components/tooltip/TooltipManager";
export default defineComponent({
  name: "TooltipContainer",
  components: { Tooltip },
  data() {
    return {
      isHidden: true,
      objectToFocus: null as Object | null,
      currentTitle: "",
      currentMessage: "",
    }
  },
  methods: {
    focusElement(element: HTMLElement) {
      if (!element) return;
      const focusedArea: HTMLElement | null = document.querySelector('.focused-area');
      const toolTip: HTMLElement | null = document.querySelector('.tooltip-popup');
      const scrollOffset = window.pageYOffset;
      if (focusedArea) {
        focusedArea.style.width = `${element.offsetWidth}px`;
        focusedArea.style.height = `${element.clientHeight}px`;
        focusedArea.style.left = `${element.offsetLeft}px`;
        focusedArea.style.top = `${element.offsetTop - scrollOffset}px`;
        focusedArea.style.borderRadius = `${getComputedStyle(element).borderRadius}`
        if (toolTip) {
          toolTip.style.top = `${element.offsetTop + element.clientHeight/2 - scrollOffset}px`;
          toolTip.style.left = `${element.clientWidth + element.offsetLeft + 20}px`;
        }
      }
    },
    windowDidResize() {
      if (!this.isHidden && this.objectToFocus) {
        this.focusElement(this.objectToFocus as HTMLElement)
      }
    },
    show(objectToFocus: HTMLElement, title: string, message: string) {
      this.objectToFocus = objectToFocus;
      this.currentTitle = title;
      this.currentMessage = message;
      this.isHidden = false;
      window.addEventListener('resize', this.windowDidResize);
      // Disable scroll on the web page while the tooltip container is visible
      document.body.style.overflow = 'hidden';
      this.focusElement(objectToFocus);
    },
    close() {
      console.log("Close TooltipContainer");
      this.isHidden = true;
      this.objectToFocus = null;
      this.currentMessage = "";
      this.currentTitle = "";
      window.removeEventListener('resize', this.windowDidResize);
      document.body.style.overflow = 'scroll';      // re-enable scroll
    }
  },
  computed: {
  },
  created() {
    TooltipManager.setInstance(this);
  },
  mounted() {
  },
})
