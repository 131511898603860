<template>
    <section
        class="features-tiles section center-content"
        :class="[
            topOuterDivider && 'has-top-divider',
            bottomOuterDivider && 'has-bottom-divider',
            hasBgColor && 'has-bg-color',
            invertColor && 'invert-color'
        ]">
        <div class="container">
            <div
                class="features-tiles-inner section-inner"
                :class="[
                    topDivider && 'has-top-divider',
                    bottomDivider && 'has-bottom-divider'
                ]">
                <c-section-header :data="sectionHeader" class="center-content" />
                <div
                    class="tiles-wrap"
                    :class="[
                        pushLeft && 'push-left',
                    ]">
                    <div data-aos="fadeUp" class="tiles-item">
                        <div class="tiles-item-inner">
                            <div class="features-tiles-item-header">
                                <div class="features-tiles-item-image mb-16">
                                    <c-image
                                        :src="require('/src/LandingPage/assets/images/feature-tile-icon-05.svg')"
                                        alt="Feature tile icon 01"
                                        :width="72"
                                        :height="72" />
                                </div>
                            </div>
                            <div class="features-tiles-item-content">
                                <h4 class="mt-0 mb-8">
                                    Tillgänglighet
                                </h4>
                                <p class="m-0 text-sm">
                                  Erbjud lyssnarna möjligheten att hitta favoritsegment, nya ämnen, citat och mycker mer.
                                </p>
                            </div>
                        </div>
                    </div>

                  <div data-aos="fadeUp" data-aos-delay="200" class="tiles-item">
                    <div class="tiles-item-inner">
                      <div class="features-tiles-item-header">
                        <div class="features-tiles-item-image mb-16">
                          <c-image
                              :src="require('/src/LandingPage/assets/images/feature-tile-icon-03.svg')"
                              alt="Feature tile icon 03"
                              :width="72"
                              :height="72" />
                        </div>
                      </div>
                      <div class="features-tiles-item-content">
                        <h4 class="mt-0 mb-8">
                          Engagemang
                        </h4>
                        <p class="m-0 text-sm">
                          Låt lyssnare utforska nya avsnitt efter intressanta ämnen. Attrahera nya lyssnare genom söktjänsten som är intresserade av vissa ämnen.
                        </p>
                      </div>
                    </div>
                  </div>

                    <div data-aos="fadeUp" data-aos-delay="400"  class="tiles-item">
                        <div class="tiles-item-inner">
                            <div class="features-tiles-item-header">
                                <div class="features-tiles-item-image mb-16">
                                    <c-image
                                        :src="require('/src/LandingPage/assets/images/feature-tile-icon-04.svg')"
                                        alt="Feature tile icon 02"
                                        :width="72"
                                        :height="72" />
                                </div>
                            </div>
                            <div class="features-tiles-item-content">
                                <h4 class="mt-0 mb-8">
                                  Överblick
                                </h4>
                                <p class="m-0 text-sm">
                                  Få en överblick över vad och när ni pratat om diverse ämnen. Minska risken för upprepningar.
                                </p>
                            </div>
                        </div>
                    </div>

                  <div data-aos="fadeUp" data-aos-delay="400"  class="tiles-item">
                    <div class="tiles-item-inner">
                      <div class="features-tiles-item-header">
                        <div class="features-tiles-item-image mb-16">
                          <c-image
                              :src="require('/src/LandingPage/assets/images/feature-tile-icon-02.svg')"
                              alt="Feature tile icon 02"
                              :width="72"
                              :height="72" />
                        </div>
                      </div>
                      <div class="features-tiles-item-content">
                        <h4 class="mt-0 mb-8">
                          Enkel integrering
                        </h4>
                        <p class="m-0 text-sm">
                          Smidig och enkel integrering på er egen hemsida med vår widget.
                        </p>
                      </div>
                    </div>
                  </div>


                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { SectionTilesProps } from '/src/LandingPage/utils/SectionProps.js'
import CSectionHeader from '/src/LandingPage/sections/partials/SectionHeader.vue'
import CImage from '/src/LandingPage/components/elements/Image.vue'

export default {
  name: 'CFeaturesTiles',
  components: {
    CSectionHeader,
    CImage
  },
  mixins: [SectionTilesProps],
  data() {
    return {
      sectionHeader: {
        title: 'Vad är Pearch?',
        paragraph: 'Pearch är en sökmotor för podcasts. Tjänsten söker igenom allt som sagts i podcastens samtliga ' +
            'avsnitt och ger resultat över matchade avsnitt, samt tidpunkt i avsnitten. ' +
            'Varför inte bara använda Google? Jo, sökmotorn använder transkriberade avsnitt' +
            ' för att fånga podcastens innehåll. Pearch är i beta och all feedback uppmuntras!'
      }
    }
  }
}
</script>