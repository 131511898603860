
import InsightHandler from "@/sharedAssets/statistics/InsightHandler";
import {Podcast} from "@/Application/model/Podcast";
import {StreamingPlatform} from "@/Application/model/StreamingPlatform";

export default {
  name: "PlatformPlayButton",
  data() {
    return {
      StreamingPlatform: StreamingPlatform
    }
  },
  methods: {
    logPlatformClick(streamingPlatform: StreamingPlatform, query: string, episodeNumber: number, episodeName: string) {
      InsightHandler.platformClickInsight(Podcast.Alexosigge, query, episodeNumber, episodeName, streamingPlatform);
    },
  },
  props: {
    streamingPlatform: {type: String, required: true},
    url: {type: String, required: true},
    query: {type: String, required: true},
    episodeNumber: {type: Number, required: true},
    episodeName: {type: String, required: true},

  }
}
