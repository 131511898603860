export const tooltipDictionary: { [name: string]: ToolTipModel } = {
    "tooltip_episodeTimeStamp": {
        "title": "Tips!",
        "message": "Klicka på tidsstämpeln för att spela avsnittet direkt från rätt tid."
    }
}

export interface ToolTipModel {
    title: string,
    message: string
}
