<template>
  <div v-on:mouseleave="hideInfoBoxOnDelay" class="container">
    <input class="search-bar" enterkeyhint="search" type="text" v-model="query"
           @keyup.enter="search(); hideInfoBox(); handleIosKeyboard();" placeholder="Sök">
    <button class="search-button" v-on:click="search(); hideInfoBox();">Sök</button>
    <Samarbete :page="page" v-on:mouseleave="hideInfoBoxOnDelay" @isShowInfoBox="onCLickChild" class="info-box"/>
    <Transition name="fade">
      <InfoBox :page="page" v-on:mouseenter="setMouseOnInfoBox" v-on:mouseleave="removeMouseOnInfoBox"
               v-if="showInfoBox"/>
    </Transition>
    <TipBox class="tipbox"/>
  </div>
</template>

<script>

import InfoBox from "@/Application/components/InfoBox";
import Samarbete from "@/Application/components/Samarbete";
import TipBox from "@/Application/components/TipBox";
import {SortOrder} from "@/Application/model/SortOrder";

export default {
  name: "AoSWidgetTopSearchbar",
  components: {Samarbete, InfoBox, TipBox},
  props: {
    page: String
  },
  data() {
    return {
      query: '',
      showInfoBox: false,
      isMouseOnInfoBox: false,
    }
  },
  mounted() {
    if (this.$route.query.q) {
      this.query = this.$route.query.q;
    }
  },
  methods: {
    search() {
      if (this.query.length > 0) {
        this.$router.push({
          path: '/widget/aos-search',
          query: {q: this.query, sort: SortOrder.mostHits}
        });
      }
    },
    onCLickChild(value) {
      this.showInfoBox = value;
      if (this.showInfoBox) {
        document.getElementById("info").style.opacity = "1";
      } else {
        document.getElementById("info").style.opacity = "0.6";
      }
    },
    hideInfoBox() {
      if (!this.isMouseOnInfoBox) {
        this.showInfoBox = false;
        document.getElementById("info").style.opacity = "0.6";
      }
    },
    setMouseOnInfoBox() {
      this.isMouseOnInfoBox = true;
    },
    removeMouseOnInfoBox() {
      this.isMouseOnInfoBox = false;
    },
    hideInfoBoxOnDelay() {
      setTimeout(this.hideInfoBox, 250);
    },
    handleIosKeyboard() {
      document.getElementsByClassName("search-bar")[0].blur();
      window.scrollTo(0, -100);
    }
  },
}
</script>

<style scoped>

.tipbox {
  opacity: 0;
}

.container {
  margin: auto;
  margin-top: 1rem !important;
  width: min(46rem, 90%);
  /*max-width: 46rem;*/
  height: min(6vh, max(6vw, 40px));
  text-align: left;
  justify-content: center;
}

.info-box {
  width: 80%;
}

.search-bar {
  display: inline;
  margin: auto;
  width: 75%;
  height: 50px;
  padding-left: max(7.5%, 50px);
  border-radius: 100px;
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-width: 0px;
  font-size: 90%;
  background-image: url('../assets/searchIcon.png');
  background-repeat: no-repeat;
  background-size: 1.2rem;
  background-position: max(3.5%, 20px) 50%;
  float: left;
  margin-bottom: 0.2rem;
}

.search-button {
  display: inline;
  /*width: 4.5rem;*/
  height: 53px;
  width: 11%;
  border-radius: 2rem;
  outline: none;
  background-image: linear-gradient(hsl(24, 100%, 55%), hsl(12, 100%, 55%));
  /*background-color: hsl(18, 100%, 55%);*/
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-width: 0px;
  font-size: min(0.95rem, 3vw);
  color: white;
  cursor: pointer;
  float: right;
}

.search-button:hover {
  /*background-color: hsl(22, 100%, 55%);*/
  background-image: linear-gradient(hsl(22, 100%, 55%), hsl(22, 100%, 55%));
}


@media (max-width: 48rem) {

  .container {
    width: 95%;
  }

  .search-button {
    width: 12%;
  }

}

@media (max-width: 1000px) {
  .container {
    margin-top: 0 !important;
  }
}

@media (max-width: 450px) {

  .search-bar {
    height: 45px;
    float: none;
    width: 80%;
  }

  .search-button {
    display: none;
  }

  .container {
    text-align: center;
  }

}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.fade-enter-to, .fade-leave-from {
  opacity: 1;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.4s ease !important;
}

</style>
