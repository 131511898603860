<template>
  <div id="infoDiv" >
     <p id="textInInfobox">

    <span class="point"> <!-- &#8226; --> </span>För många sökträffar? Söka efter citat? Omslut sökorden inom <span class="bold"> citattecken (" ")</span>.
    <br> <br>
       Textkvalitet och tidsangivelser kan variera på grund av auto-genererad transkribering och dynamiska annonser.
       <br><br>
    Då tjänsten är i utveckling mottages gladeligen all feedback på
    <span class="bold mail" onclick="window.open('mailto:hello@pearch.se')">hello@pearch.se</span> <br> <br>
    Vill du ha möjlighet att söka i andra favoritpoddar? Sprid ordet eller kontakta oss!
       <br><br> <a v-on:click="landingPageInfoButtonInsight()" href="https://www.pearch.se/about" target="_blank">Om Pearch</a>
  </p>
  </div>
</template>

<script>
import InsightHandler from "@/sharedAssets/statistics/InsightHandler";
import {Podcast} from "@/Application/model/Podcast";
import {LandingPageButton} from "@/Application/model/LandingPageButton";

export default {
  name: "InfoBox",
  props: {
    page: String
  },
  mounted() {
    InsightHandler.infoTextViewInsight(Podcast.Alexosigge, this.$props.page);
  },
  
  methods: {
    landingPageInfoButtonInsight() {
      InsightHandler.landingPageClickInsight(Podcast.Alexosigge, this.$props.page, LandingPageButton.InfoTextButton);
    }
  }
}
</script>

<style scoped>

#infoDiv {
  transition: 1s linear;
  position: absolute;
  border: 0.5px solid rgba(14, 13, 19, 0.3);
  line-height: 1.7;
  padding: 0.2rem 1.6rem 0.3rem;
  background-color: white;
  height: fit-content;
  z-index: 100;
  margin: 0.9rem auto auto;
  width: 30rem;
  max-width: 60%;
  margin-left: min(3rem, 10vw);
  box-shadow: rgba(0, 0, 0, 0.04) 0px 1px 4px, rgba(27, 31, 35, 0.04) 0px 0px 0px 1px;
  border-radius: 1rem;
}

#titleInInfobox {
  text-align: left;
  font-size: 1rem;
  font-weight: 400;
}

#textInInfobox {
  text-align: left;
  font-size: 0.8rem;
  margin-top: 1rem;
}

.point {
  opacity: 1;
  font-size: 0.7rem;
  color: dimgrey;
}

.bold {
  font-weight: bold;
}

.mail {
  cursor: pointer;
}

.mail:hover {
  opacity: 0.7;
}

</style>