import { reactive } from 'vue'
import {EpisodeQueryResult} from "@/Application/model/EpisodeQueryResult";
import ApiClient from "@/Application/remote/ApiClient";
import HttpError from "@/Application/exceptions/HttpError";
import {SortOrder} from "@/Application/model/SortOrder";

export const currentQuery = reactive({
    query: null as string | null,
    searchResults: [] as EpisodeQueryResult[],
    wordsToHighlight: [] as string[],
    isError: false,
    searchInProgress: false,
    isExactSearch: false,
    errorMessage: null as string | null,
    isResultsSliced: false,
    fullSearch: false as boolean
})

const resetCurrentQuery = () => {
    currentQuery.query = null;
    currentQuery.isExactSearch = false;
    currentQuery.searchResults = []
    currentQuery.wordsToHighlight = []
    currentQuery.isError = false;
    currentQuery.isResultsSliced = false;
    currentQuery.fullSearch = false;
}

export const makeQuery = (async (query: string, limitQuery: boolean = true, sortOrder: SortOrder) => {

    currentQuery.searchInProgress = true;
    currentQuery.fullSearch = !limitQuery;

    try {
        const response = await ApiClient.query(query, limitQuery, sortOrder);
        currentQuery.isResultsSliced = response.isResultsSliced
        currentQuery.isExactSearch = response.isExactSearch
        currentQuery.wordsToHighlight = wordsToHighlight(query, response.isExactSearch)
        currentQuery.searchResults = response.searchResults;
        currentQuery.searchInProgress = false;
        return response
    } catch(err) {
        currentQuery.isError = true;
        currentQuery.searchResults = [];
        currentQuery.searchInProgress = false;
        if (typeof err === typeof HttpError) {
            currentQuery.errorMessage = err.message;
        }
        return err.code;
    }

})

const wordsToHighlight = ((query: string, isExactSearch: boolean): string[] => {
      if (isExactSearch) {
        return [query.replaceAll('"', "")];
      } else {
          return query.split(" ");
      }
})
