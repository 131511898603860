<template>
  <div class="container">
    <LoaderAnimation id="loader-anim" v-if="currentQuery.searchInProgress"/>

    <div id="serverTimeOut" v-if=" (isError === true & searchInProgress === false)"> {{ errorMessage }}</div>

    <div id="results" v-if="!searchQueryEmpty && !currentQuery.searchInProgress">
      <p class="query-title" v-if="searchResults">
        Du sökte på <span class="highlighted">{{ query }}</span> som hittades i {{ numResults }} avsnitt
      </p>

      <SortBy class="sort-by" :preSelectedSortOrder="sortOrder" v-if="searchResults && searchResults.length > 0" @sort-order-changed="sortOrderChanged"/>

      <div class="divider" v-if="searchResults"/>

      <EpisodeList
          id="query-result-list"
          :searchResults="searchResults"
          :query="query"
          :isExactSearch="isExactSearch"
          :max_hit_in_episode="max_hit_in_episode"
      />

      <button
          id="load-more-button"
          v-if="searchResults !== [] & isResultsSliced === true"
          v-on:click="search(false); visaFlerClickInsight()"
      >visa fler
      </button>

    </div>
  </div>

</template>

<script lang="ts">
import {defineComponent} from 'vue';
import LoaderAnimation from "@/Application/components/animations/LoaderAnimation.vue";
import EpisodeList from "@/Application/components/QueryResults/EpisodeList.vue";
import {unwrapQueryParam} from "@/Application/util/QueryParamaterUtil";
import {QueryResponse} from "@/Application/model/QueryResponse";
import {EpisodeQueryResult} from "@/Application/model/EpisodeQueryResult";
import {currentQuery, makeQuery} from "@/Application/query/Query";
import InsightHandler from "@/sharedAssets/statistics/InsightHandler";
import {Podcast} from "@/Application/model/Podcast";
import SortBy from "@/Application/components/SortBy.vue";
import {SortOrder} from "@/Application/model/SortOrder";

export default defineComponent({
  name: "QueryResults",
  components: {SortBy, LoaderAnimation, EpisodeList},
  watch: {
    '$route.query.q'() {
      this.query = unwrapQueryParam(this.$route.query.q);
      this.search(true);
    }
  },
  mounted() {
    this.query = unwrapQueryParam(this.$route.query.q);
    this.search(true);
    try {
      localStorage.removeItem("seenMessageBox");
    } catch (e) {
      console.log(e)
    }

  },
  data() {
    return {
      pageName: "computerQueryPage",
      searchResults: [] as EpisodeQueryResult[],
      query: '' as string | null,
      url: "https://gtkro7amnj.execute-api.eu-north-1.amazonaws.com/Prod/query?queryString=",
      searchInProgress: false,
      isError: false,
      numResults: 0,
      // response: null as QueryResponse | null,
      fullSearch: false,
      isResultsSliced: false,
      errorMessage: "",
      isExactSearch: false,
      currentQuery: currentQuery,
      max_hit_in_episode: 0,
    };
  },
  methods: {
    async sortOrderChanged(sortOrder: SortOrder) {
      await this.$router.replace({
        path: '/widget/aos-search',
        query: {q: this.query, sort: sortOrder}
      });

      InsightHandler.sortByChanged(Podcast.Alexosigge, "", sortOrder)

      if (this.isResultsSliced) {
        this.search(true, true)
        return
      }

      switch (sortOrder) {
        case SortOrder.newest: {
          this.searchResults.sort((a, b) => {
            return b.number - a.number;
          })
          break;
        }
        case SortOrder.oldest: {
          this.searchResults.sort((a, b) => {
            return a.number - b.number;
          })
          break;
        }
        case SortOrder.mostHits: {
          this.searchResults.sort((a, b) => {
            return b.numHits - a.numHits;
          })
          break;
        }
        case SortOrder.leastHits: {
          this.searchResults.sort((a, b) => {
            return a.numHits - b.number;
          })
          break;
        }
        case SortOrder.nameDescending: {
          this.searchResults.sort((a, b) => {
            const aStrippedTitle = a.title.replace('"', "");
            const bStrippedTitle = b.title.replace('"', "");
            return (aStrippedTitle > bStrippedTitle) ? -1 : 1;
          })
          break;
        }
        case SortOrder.nameAscending: {
          this.searchResults.sort((a, b) => {
            const aStrippedTitle = a.title.replace('"', "");
            const bStrippedTitle = b.title.replace('"', "");
            return (aStrippedTitle < bStrippedTitle) ? -1 : 1;
          })
          break;
        }
      }
    },
    async search(limitQuery: boolean = true, sortOrderClicked: boolean = false) {

      this.isError = false;

      if (this.query === "" || this.query === null) {
        this.searchResults = [];
        this.numResults = 0;
        return;
      }

      if (limitQuery && !sortOrderClicked) {
        InsightHandler.queryInsight(Podcast.Alexosigge, this.query, this.getReferer());
      }

      const queryResponse: QueryResponse | undefined = await makeQuery(this.query, limitQuery, this.sortOrder);

      // @ts-ignore
      if (queryResponse === 300) {
        this.errorMessage = "Prova med en längre sökterm!";
        this.isError = true;
        this.numResults = 0;
        this.searchResults = [];
        this.isResultsSliced = false;
        // @ts-ignore
      } else if (queryResponse === 502) {
        this.errorMessage = "Något gick snett. Prova en annan sökning!";
        this.isError = true;
        this.numResults = 0;
        this.searchResults = [];
        this.isResultsSliced = false;
      } else if (queryResponse) {
        //console.log(`queryResponseee: ${JSON.stringify(queryResponse)}`);
        this.numResults = queryResponse.numEpisodes;
        this.isResultsSliced = queryResponse.isResultsSliced;
        this.isExactSearch = queryResponse.isExactSearch;
        this.searchResults = queryResponse.searchResults;
        this.max_hit_in_episode = queryResponse.max_hit_in_episode;
        this.showTipBoxHandler()
      }

    },
    getReferer() {
      if (this.$router.options.history.state.back) {
        return this.$router.options.history.state.back.toString().split("?")[0];
      }
      return "unknown"
    },
    visaFlerClickInsight() {
      InsightHandler.visaFlerClickInsight(Podcast.Alexosigge, this.query);

    },
    showTipBoxHandler() {
      setTimeout(this.showTipBox, 500)
    },
    showTipBox() {
      try {
        if (!localStorage.getItem("seenMessageBox")) {
          if (this.searchResults.length == 0) {
            return;
          }
          for (let hit of this.searchResults) {
            if (hit.numHits < this.max_hit_in_episode) {
              return
            }
          }
          // @ts-ignore
          document.getElementsByClassName("tipbox")[0].style.opacity = 1;
          localStorage.setItem("seenMessageBox", "true");
          this.handleScrollEvent();
        }
      } catch (e) {
        console.log(e)
      }

    },
    handleScrollEvent() {
      let tipbox = document.getElementsByClassName("tipbox")[0]
      document.addEventListener('scroll', function handler(e) {
        let threshold;
        const mobileThreshold = 70;
        const desktopThreshold = 45;
        // @ts-ignore
        if (getComputedStyle(document.getElementById("mobileDiv")).color === "rgba(17, 51, 51, 0.467)") {
          threshold = mobileThreshold;
        } else {
          threshold = desktopThreshold;
        }

        if (tipbox.getBoundingClientRect().top < threshold) {
          // @ts-ignore
          document.getElementsByClassName("tipbox")[0].style.opacity = 0;
          if (e.currentTarget) {
            e.currentTarget.removeEventListener("scroll", handler);
          }
        }
      })
    }
  },
  computed: {
    searchQueryEmpty() {
      if (this.query === "" || this.query === null) {
        return true
      }
      return false;
    },
    sortOrder(): SortOrder {
      const sortOrderQueryParam: string = unwrapQueryParam(this.$route.query.sort)
      const sortOrderFound = (Object.keys(SortOrder) as Array<keyof typeof SortOrder>).filter((sortOrder) => {
        if (sortOrderQueryParam === sortOrder) {
          return sortOrder
        }
      })

      if (sortOrderFound.length > 0) {
        return sortOrderFound[0] as SortOrder
      } else {
        return SortOrder.mostHits
      }

    }
  }
})
</script>

<style scoped>


* {
  font-size: 100%;
}

.sort-by {
  display: block;
  text-align: right;
  position: relative;
  margin: 0 0 0 100%;
  transform: translateX(-100%);
  z-index: 10;
  /*height: 35px;*/
  width: min(200px, 35vw);
}

.container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 11rem;
}


#query-result-list {
  height: 100%;
  width: 100%;
  margin: auto;
}

#results {
  margin-bottom: 3%;
}

.query-title {
  font-family: NunitoSemiBold, serif;
  font-weight: 500;
  margin: 2em auto 1.5em;
  font-size: 1.8rem;
  width: 85%;
  line-height: 1.7;
}

.highlighted {
  color: white;
  background-image: linear-gradient(hsl(24, 100%, 55%), hsl(12, 100%, 55%));
  border-radius: .25em;
  padding: 0 0.2em;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: black;
  margin: min(1.5rem, 15px) auto 0rem auto;
  opacity: 0.85;
}


#loader-anim {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.episode-title-text b {
  display: inline-block;
  font-weight: 700;
}

.episode-title-text p {
  display: inline-block;
  font-weight: 500;
}

#serverTimeOut {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button:hover {
  background-color: #f7fafa;
}

.button:focus {
  box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
  outline: 0;
}

#load-more-button:hover {
  background-color: #f7fafa;
}

#load-more-button {
  background-color: #fff;
  border: 1px solid #d5d9d9;
  border-radius: 8px;
  box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
  box-sizing: border-box;
  color: #0f1111;
  cursor: pointer;
  font-size: 0.85rem;
  line-height: 25px;
  padding: 0 10px 0 11px;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: 80px;
  margin-top: 3rem;
}


.hit-in-episode-header img {
  height: 1.2em;
  width: auto;
  display: inline-block;
  margin-left: 1.2rem;
  vertical-align: center;

}

.hit-in-episode-header img:first-of-type {
  margin-left: 1rem;
}


@media (max-width: 46rem) {
  .query-title {
    margin: 2em auto 2em;
    font-size: max(4vw, 1.3rem);
  }
}

@media (max-width: 450px) {

  .query-title {
    margin: 2.7em auto min(1.5em, 15px);
    font-size: 1.2rem;
  }

  .divider {
    opacity: 0.4;
  }

  #load-more-button {
    font-size: 0.7rem;
    margin-top: 2rem;
  }

  .container {
    padding-bottom: 250px;
  }

}

@media (max-height: 390px) {
  .container {
    min-width: 30vw;
  }
}

</style>
