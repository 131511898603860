<template>
  <div id="landingPage">
    <CHeader :page="$options.name" id="pearchHeader" data-aos="fadeDown" data-aos-duration="800" nav-position="right" />
    <div id="body">
      <c-hero-full class="illustration-section-01" />
      <c-clients top-divider bottom-divider/>
      <FeaturesTiles bottom-divider />
      <ContactUs :page="$options.name" class=""/>
      <Team top-divider id = "team"/>
    </div>
    <CFooter />
  </div>
  <div id="mobileDiv"></div>
</template>

<script>
import CHeroFull from '../components/sections/HeroFull.vue'
import CClients from '../components/sections/Clients'
import FeaturesTiles from '../components/sections/FeaturesTiles.vue'
import Team from '../components/sections/Team.vue';
import ContactUs from '../components/sections/ContactUs.vue';
import CHeader from '/src/LandingPage/components/layout/Header.vue';
import CFooter from '/src/LandingPage/components/layout/Footer.vue';
import InsightHandler from "@/sharedAssets/statistics/InsightHandler";

export default {
  name: 'Home',
  components: {
    ContactUs,
    Team,
    CHeroFull,
    CClients,
    FeaturesTiles,
    CHeader,
    CFooter,
  },
  data() {
    return {
      layout: 'div',
    }
  },
  mounted() {
    document.body.classList.add('is-loaded');
    import("/src/LandingPage/assets/scss/style.scss");
    InsightHandler.landingPageViewInsight(this.$options.name, this.isTouchScreen())
  },
  methods: {
    isTouchScreen() {
      return getComputedStyle(document.getElementById("mobileDiv")).color === "rgba(17, 51, 51, 0.467)";
    }
  }
}
</script>



<style lang="scss">

#pearchHeader {
  font-family: Inter, serif;
}

#landingPage {
  font-family: RobotoReg, serif !important;
}


[data-aos="fadeDown"] {
  opacity: 0;
  transition-property: transform, opacity;

  &.aos-animate {
    opacity: 1;
  }

  @media (min-width: 800px) {
    transform: translateY(-20px);
  }
}

[data-aos="fadeUp"] {
  opacity: 0;
  transition-property: transform, opacity;

  &.aos-animate {
    opacity: 1;
  }

  @media (min-width: 800px) {
    transform: translateY(20px);
  }
}

#body {
  color: #575e86;
}


#mobileDiv {
  display: none;
  color: black;
}

@media (hover: none) {
  #mobileDiv {
    color: #1337;
  }
}


</style>

