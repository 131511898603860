<template>
  <div class="container">

    <div class="header">
      <TimeStampPlayButton
          :logTimeStampClick="logTimeStampClick"
          :url="timeStampUrl"
          :time="getFormattedTimestamp(hit.time)"/>
    </div>

    <Highlighter
        class="text"
        highlightClassName="highlighted-text"
        :highlightStyle="{ backgroundColor: 'white', 'font-weight': 'bold' }"
        :searchWords="wordsToHighlight"
        :textToHighlight=" '...' + hit.text + '...' "
        :autoEscape="true"
    />

  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
// @ts-ignore
import Highlighter from "vue-highlight-words";
import {DateUtil} from "@/Application/util/DateUtil";
import {QueryHit} from "@/Application/model/QueryHit";
import {currentQuery} from "@/Application/query/Query";
import {StreamingPlatform} from "@/Application/model/StreamingPlatform";
import InsightHandler from "@/sharedAssets/statistics/InsightHandler";
import {Podcast} from "@/Application/model/Podcast";
import TimeStampPlayButton from "@/Application/components/QueryResults/TimeStampPlayButton.vue";

export default defineComponent({
  name: "HitInEpisode",
  components: {TimeStampPlayButton, Highlighter},
  props: {
    query: String,
    episodeName: String,
    episodeNumber: Number,
    hit: Object as PropType<QueryHit>,
    spotifyUrl: String,
  },
  data() {
    return {
      wordsToHighlight: currentQuery.wordsToHighlight,
      timeStampUrl: String
    }
  },
  mounted() {
    this.changeAndroidFont();
    this.createTimeStampUrl()
  },
  methods: {
    getFormattedTimestamp(timestamp: number) {
      return DateUtil.getFormattedTimestamp(timestamp)
    },
    logTimeStampClick() {
      InsightHandler.timestampClickInsight(Podcast.Alexosigge, this.query, this.episodeNumber, this.episodeName, this.hit?.time, StreamingPlatform.SPOTIFY);
    },
    createTimeStampUrl() {
      const hit = this.hit;
      const episodeName = this.episodeName;
      const episodeNumber = this.episodeNumber;
      if (hit && episodeName && episodeNumber) {
        // @ts-ignore
        this.timeStampUrl = this.spotifyUrl + "?t=" + Math.round(hit.time * 60)
      }
    },
    changeAndroidFont() {
      if (navigator.userAgent.match(/Android/i)) {
        let timestamps = document.getElementsByClassName("timestamp");
        for (let i = 0; i < timestamps.length; i++) {
          //@ts-ignore
          timestamps[i].style.letterSpacing = 0;
        }
      }
    },
  }

});

</script>

<style scoped>

.container {
  text-align: left;
  margin-left: min(1.5em, 4vw);
  margin-top: 1rem;
}

.header {
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  height: 2em;
}

.timestamp {
  font-weight: bold;
  width: fit-content;
  font-size: min(0.8rem, 3vw);
  display: inline-block;
  vertical-align: center;
}

.text {
  display: block;
  font-size: min(0.85rem, 3.4vw);
  font-weight: normal;
  margin-right: min(3em, 4vw);
  margin-top: min(0.7rem, 2vw);
  line-height: 1.5;
}

.highlighted-text {
  font-weight: bold;
}

@media (max-width: 600px) {
  .timestamp {
    letter-spacing: -1px;
  }
}

</style>
