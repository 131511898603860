<template>
  <div v-on:click="changeIsShowInfoBox()" id="samarbeteDiv">
    <img v-on:mouseenter="setHighInfoOpacity()" v-on:mouseleave="setLowInfoOpacity()"
         src="@/Application/assets/info.png" id="info"/>
    <p id="samarbete">Presenteras i samarbete med </p>
    <a v-on:click.stop="landingPagePearchLogoClickInsight()" id="company-href" href="https://www.pearch.se" target="_blank">
      <img src="@/Application/assets/logos/pearch_logo_orange.svg" id="companyName"/>
    </a>

  </div>
</template>

<script>
import InsightHandler from "@/sharedAssets/statistics/InsightHandler";
import {Podcast} from "@/Application/model/Podcast";
import {LandingPageButton} from "@/Application/model/LandingPageButton";

export default {
  data() {
    return {
      showInfo: false,
    }
  },
  props: {
    page: String
  },
  name: "Samarbete",
  methods: {
    changeIsShowInfoBox() {
      this.showInfo = !document.getElementById("textInInfobox");
      this.$emit('isShowInfoBox', this.showInfo);
    },
    showInfoBoxComputer() {
      if (!this.isTouchScreen()) {
        this.showInfo = true;
        this.$emit('isShowInfoBox', this.showInfo);
      }
    },
    setHighInfoOpacity() {
      if (!this.isTouchScreen()) {
        document.getElementById("info").style.opacity = "1";
      }
    },
    setLowInfoOpacity() {
      if (!this.showInfo) {
        document.getElementById("info").style.opacity = "0.6";
      }
    },
    landingPagePearchLogoClickInsight() {
      InsightHandler.landingPageClickInsight(Podcast.Alexosigge, this.$props.page, LandingPageButton.PearchLogo);
    },
    isTouchScreen() {
      return getComputedStyle(document.getElementById("mobileDiv")).color === "rgba(17, 51, 51, 0.467)";
    },
  }
}
</script>

<style scoped>

#samarbeteDiv {
  margin-left: 0.2rem;
  display: flex;
  vertical-align: center;
  align-items: center;
  /*height: fit-content;*/
  /*width: fit-content;*/
  text-align: left;
  padding-top: 0.5rem;
}

#samarbete {
  font-size: min(0.75rem, max(2vw, 0.70rem));
  display: flex;
  margin: 0 0 0 0.5rem;
}


#companyName {
  width: 38px;
  margin-left: 0.35rem;
  margin-right: 0;
}

#company-href {
  height: 100%;
  display: flex;
  flex-direction: row;
}

#company-href:hover {
  cursor: pointer;
  opacity: 0.8;
}

#info {
  font-weight: bold;
  display: inline-block;
  margin-left: 5%;
  height: min(0.75rem, max(2vw, 0.8rem));
  width: auto;
  opacity: 0.6;
  cursor: pointer;
}

@media (max-width: 450px) {

  #info {
    margin-left: 0;
  }

  #samarbeteDiv {
    margin-left: -0.8rem !important;
    display: inline-flex;
  }

  #companyName {
    margin-left: 0.30rem;
    width: 36px;
  }


}


</style>
