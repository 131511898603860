
import {defineComponent, PropType} from 'vue';
import EpisodeHeader from "@/Application/components/QueryResults/EpisodeHeader.vue";
import EpisodeHitList from "@/Application/components/QueryResults/EpisodeHitList.vue";
import { EpisodeQueryResult } from "@/Application/model/EpisodeQueryResult";
import InsightHandler from "@/sharedAssets/statistics/InsightHandler";
import {Podcast} from "@/Application/model/Podcast";
import TooltipManager from "@/Application/components/tooltip/TooltipManager";


export default defineComponent({
  name: "Episode",
  components: {EpisodeHitList, EpisodeHeader },
  props: {
    query: String,
    result: Object as PropType<EpisodeQueryResult>,
    max_hit_in_episode: Number
    // wordsToHighlight: Array as PropType<string[]>
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    open() {
      if (this.$props.result)
        if (this.$props.result.numHits > 0) {
        this.isOpen = !this.isOpen;
      }

      if (this.isOpen) {
        TooltipManager.instance().show('tooltip_episodeTimeStamp', '.timestamp-play-button', 300);
      }
    },
    episodeClickInsight() {
      if (this.$props.result) {
        if (this.isOpen) {
          InsightHandler.episodeClickInsight(Podcast.Alexosigge, this.$props.query, this.$props.result.number, this.$props.result.title)
        }
      }
    }
  }
})
