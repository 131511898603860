import { createApp } from 'vue'
import App from './App.vue'
import {createRouter, createWebHistory} from "vue-router";
import AoSWidgetPage from "@/Application/components/Pages/Widget/AoSWidgetPage.vue";
import AoSWidgetStandalone from "@/Application/components/Pages/Widget/AoSWidgetStandalone.vue";
import EmptyPage from "./LandingPage/views/EmptyPage.vue"
import Pearch from "./LandingPage/views/Pearch.vue"
// @ts-ignore
import AOS from 'aos'
import 'aos/dist/aos.css'

const routes = [
    { path: '/', name: 'StartPage', component: Pearch },
    { path: '/widget/aos-search', name: 'AoSWidget', component: AoSWidgetPage },
    { path: '/widget/aos-start', name: 'AoSWidgetStandalone', component: AoSWidgetStandalone },
    { path: '/:pathMatch(.*)*', name: 'not-found', component: EmptyPage }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})
const app = createApp(App)

const width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
let offset;
if (width > 800) {
    offset = 300;
} else {
    offset = 0
}

AOS.init({
    duration: "800",
    offset: offset,
    once: true,
})

app.use(router);
app.mount('#app');

