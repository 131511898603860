
import {defineComponent, PropType} from "vue";
import {SortOrder} from "@/Application/model/SortOrder";
import {SortOrderText} from "@/Application/model/SortOrderText";

export default defineComponent({
  name: "SortBy",
  mounted() {
    window.addEventListener('click', (e) => {
      if (!this.$el.contains(e.target)) {
        this.isOpen = false
      }
    })
  },
  methods: {
    toggleOpen() {
      this.isOpen = !this.isOpen;
    },
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    selectSortOrder(newSortOrder: SortOrder) {
      if (this.selectedSortOrder === newSortOrder) {
        this.isOpen = false;
        return
      }

      this.selectedSortOrder = newSortOrder;
      this.isOpen = false;
      this.$emit('sortOrderChanged', newSortOrder)
    },
    sortOrderToText(sortOrder: SortOrder) {
      return SortOrderText[sortOrder]
    },
  },
  data() {
    return {
      isOpen: false,
      selectedSortOrder: this.$props.preSelectedSortOrder,
      sortOrders: [SortOrder.mostHits, SortOrder.newest, SortOrder.oldest, SortOrder.nameAscending, SortOrder.nameDescending],
    }
  },
  props: {
    preSelectedSortOrder: {
      type: String as PropType<SortOrder>,
      required: true
    }
  },
});

