<template>
  <a v-bind:href="url" target="_blank"
     v-on:click.stop="logPlatformClick(streamingPlatform, query, episodeNumber, episodeName)">

    <div class="container unselectable">
      <div class="image-container ">
        <img v-if="streamingPlatform === StreamingPlatform.SPOTIFY" class="spotify-logo logos"
             src="@/Application/assets/logos/Spotify_Logo.png" alt="">
        <img v-if="streamingPlatform === StreamingPlatform.APPLE" class="apple-podcasts-logo logos"
             src="@/Application/assets/logos/Apple_Podcast_Icon.png" alt="">
      </div>

      <div class="text-container">
        Spela
      </div>
    </div>

  </a>

</template>

<script lang="ts">
import InsightHandler from "@/sharedAssets/statistics/InsightHandler";
import {Podcast} from "@/Application/model/Podcast";
import {StreamingPlatform} from "@/Application/model/StreamingPlatform";

export default {
  name: "PlatformPlayButton",
  data() {
    return {
      StreamingPlatform: StreamingPlatform
    }
  },
  methods: {
    logPlatformClick(streamingPlatform: StreamingPlatform, query: string, episodeNumber: number, episodeName: string) {
      InsightHandler.platformClickInsight(Podcast.Alexosigge, query, episodeNumber, episodeName, streamingPlatform);
    },
  },
  props: {
    streamingPlatform: {type: String, required: true},
    url: {type: String, required: true},
    query: {type: String, required: true},
    episodeNumber: {type: Number, required: true},
    episodeName: {type: String, required: true},

  }
}
</script>

<style scoped>

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a {
  text-decoration: none;
  color: initial;
}

.logos {
  height: 12px;
}

.image-container {
  margin-left: 15%;
}

.text-container {
  font-size: 12px;
  margin: auto auto auto 10%;
}

.container {
  display: inline-flex;
  width: 71px;
  height: 21px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.10);
  border-width: 1px;
  border-radius: 30px;
}

.container:hover {
  cursor: pointer;
  border-color: rgba(0, 0, 0, 0.35);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12) inset;
  background-color: rgba(63, 58, 58, 0.04);
}

@media (max-width: 600px) {
  .text-container {
    font-size: 10px;
  }

  .container {
    width: 57px;
    height: 20px;
    align-items: center;
    background-color: rgba(63, 58, 58, 0.02);
  }

  .logos {
    height: 11px;
  }
  .image-container {
    margin-left: 12%;
  }
}

</style>