
import {defineComponent} from "vue";

export default defineComponent({
  name: "TooltipPopup",
  props: {
    title: String,
    message: String
  },
  methods: {
    close() {

    }
  }
})
