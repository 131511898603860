
import {DateUtil} from "@/Application/util/DateUtil";
import {defineComponent} from 'vue';
import PlatformPlayButton from "@/Application/components/QueryResults/PlatformPlayButton.vue";

export default defineComponent({
  name: "EpisodeHeader",
  components: {PlatformPlayButton},
  props: {
    query: String, // TODO Kanske sätta centralt?
    episodeNumber: Number,
    episodeName: String,
    episodePublishDate: String,
    episodeDuration: String,
    numHits: Number,
    spotifyUrl: String,
    appleUrl: String,
    max_hit_in_episode: Number
  },
  data() {
    return {
      isOpen: false
    }
  },
  mounted() {
    this.changeAndroidFont();
  },
  methods: {
    open() {
      if (this.$props.numHits) {
        if (this.$props.numHits > 0) {
          this.isOpen = !this.isOpen;
        }
      }
    },
    getFormattedDate(dateString: string) {
      return DateUtil.getFormattedDate(dateString)
    },
    getFormattedPublishDate(publishDate: string) {
      return DateUtil.getFormattedPublishDate(publishDate)
    },
    getFormattedEpisodeTitle(episodeTitle: string) {
      if (episodeTitle.length > 42 && window.matchMedia("(max-width: 700px)").matches) {
        return episodeTitle.slice(0, 42) + "..."
      }
      return episodeTitle
    },
    changeAndroidFont() {
      if (navigator.userAgent.match(/Android/i)) {
        let publishData = document.getElementsByClassName("publish-data");
        let numHits = document.getElementsByClassName("num-hits");
        for (let i = 0; i < publishData.length; i++) {
          //@ts-ignore
          publishData[i].style.letterSpacing = 0;
          //@ts-ignore
          numHits[i].style.letterSpacing = 0;
        }
      }
    }
  },
});
