<template>
  <div class="query-result-list" v-if="searchResults && searchResults.length > 0">
    <Episode
        v-for="(result, index) in searchResults"
        :query="query"
        :key="result.title"
        :result="searchResults[index]"
        :max_hit_in_episode="max_hit_in_episode"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import Episode from "@/Application/components/QueryResults/Episode.vue";
import {EpisodeQueryResult} from "@/Application/model/EpisodeQueryResult";
import {SortOrder} from "@/Application/model/SortOrder";

export default defineComponent({
  name: "EpisodeList",
  components: { Episode },
  props: {
    searchResults: Array as PropType<EpisodeQueryResult[]>,
    query: String,
    isExactSearch: null,
    max_hit_in_episode: Number,
    sortOrder: Object as PropType<SortOrder>
  },
  data() {
    return {
      wordsToHighlight() {
        return [];
      }
    }
  },
  methods: {
  },
  computed: {
    // wordsToHighlight() {
    //   return []
    //   // if (this.isExactSearch) {
    //   //   return [this.query.replaceAll('"', "")];
    //   // } else {
    //   //   return this.query.split(" ");
    //   // }
    // }
  }

})
</script>

<style scoped>

</style>
