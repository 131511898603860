
import {defineComponent} from 'vue';
import LoaderAnimation from "@/Application/components/animations/LoaderAnimation.vue";
import EpisodeList from "@/Application/components/QueryResults/EpisodeList.vue";
import {unwrapQueryParam} from "@/Application/util/QueryParamaterUtil";
import {QueryResponse} from "@/Application/model/QueryResponse";
import {EpisodeQueryResult} from "@/Application/model/EpisodeQueryResult";
import {currentQuery, makeQuery} from "@/Application/query/Query";
import InsightHandler from "@/sharedAssets/statistics/InsightHandler";
import {Podcast} from "@/Application/model/Podcast";
import SortBy from "@/Application/components/SortBy.vue";
import {SortOrder} from "@/Application/model/SortOrder";

export default defineComponent({
  name: "QueryResults",
  components: {SortBy, LoaderAnimation, EpisodeList},
  watch: {
    '$route.query.q'() {
      this.query = unwrapQueryParam(this.$route.query.q);
      this.search(true);
    }
  },
  mounted() {
    this.query = unwrapQueryParam(this.$route.query.q);
    this.search(true);
    try {
      localStorage.removeItem("seenMessageBox");
    } catch (e) {
      console.log(e)
    }

  },
  data() {
    return {
      pageName: "computerQueryPage",
      searchResults: [] as EpisodeQueryResult[],
      query: '' as string | null,
      url: "https://gtkro7amnj.execute-api.eu-north-1.amazonaws.com/Prod/query?queryString=",
      searchInProgress: false,
      isError: false,
      numResults: 0,
      // response: null as QueryResponse | null,
      fullSearch: false,
      isResultsSliced: false,
      errorMessage: "",
      isExactSearch: false,
      currentQuery: currentQuery,
      max_hit_in_episode: 0,
    };
  },
  methods: {
    async sortOrderChanged(sortOrder: SortOrder) {
      await this.$router.replace({
        path: '/widget/aos-search',
        query: {q: this.query, sort: sortOrder}
      });

      InsightHandler.sortByChanged(Podcast.Alexosigge, "", sortOrder)

      if (this.isResultsSliced) {
        this.search(true, true)
        return
      }

      switch (sortOrder) {
        case SortOrder.newest: {
          this.searchResults.sort((a, b) => {
            return b.number - a.number;
          })
          break;
        }
        case SortOrder.oldest: {
          this.searchResults.sort((a, b) => {
            return a.number - b.number;
          })
          break;
        }
        case SortOrder.mostHits: {
          this.searchResults.sort((a, b) => {
            return b.numHits - a.numHits;
          })
          break;
        }
        case SortOrder.leastHits: {
          this.searchResults.sort((a, b) => {
            return a.numHits - b.number;
          })
          break;
        }
        case SortOrder.nameDescending: {
          this.searchResults.sort((a, b) => {
            const aStrippedTitle = a.title.replace('"', "");
            const bStrippedTitle = b.title.replace('"', "");
            return (aStrippedTitle > bStrippedTitle) ? -1 : 1;
          })
          break;
        }
        case SortOrder.nameAscending: {
          this.searchResults.sort((a, b) => {
            const aStrippedTitle = a.title.replace('"', "");
            const bStrippedTitle = b.title.replace('"', "");
            return (aStrippedTitle < bStrippedTitle) ? -1 : 1;
          })
          break;
        }
      }
    },
    async search(limitQuery: boolean = true, sortOrderClicked: boolean = false) {

      this.isError = false;

      if (this.query === "" || this.query === null) {
        this.searchResults = [];
        this.numResults = 0;
        return;
      }

      if (limitQuery && !sortOrderClicked) {
        InsightHandler.queryInsight(Podcast.Alexosigge, this.query, this.getReferer());
      }

      const queryResponse: QueryResponse | undefined = await makeQuery(this.query, limitQuery, this.sortOrder);

      // @ts-ignore
      if (queryResponse === 300) {
        this.errorMessage = "Prova med en längre sökterm!";
        this.isError = true;
        this.numResults = 0;
        this.searchResults = [];
        this.isResultsSliced = false;
        // @ts-ignore
      } else if (queryResponse === 502) {
        this.errorMessage = "Något gick snett. Prova en annan sökning!";
        this.isError = true;
        this.numResults = 0;
        this.searchResults = [];
        this.isResultsSliced = false;
      } else if (queryResponse) {
        //console.log(`queryResponseee: ${JSON.stringify(queryResponse)}`);
        this.numResults = queryResponse.numEpisodes;
        this.isResultsSliced = queryResponse.isResultsSliced;
        this.isExactSearch = queryResponse.isExactSearch;
        this.searchResults = queryResponse.searchResults;
        this.max_hit_in_episode = queryResponse.max_hit_in_episode;
        this.showTipBoxHandler()
      }

    },
    getReferer() {
      if (this.$router.options.history.state.back) {
        return this.$router.options.history.state.back.toString().split("?")[0];
      }
      return "unknown"
    },
    visaFlerClickInsight() {
      InsightHandler.visaFlerClickInsight(Podcast.Alexosigge, this.query);

    },
    showTipBoxHandler() {
      setTimeout(this.showTipBox, 500)
    },
    showTipBox() {
      try {
        if (!localStorage.getItem("seenMessageBox")) {
          if (this.searchResults.length == 0) {
            return;
          }
          for (let hit of this.searchResults) {
            if (hit.numHits < this.max_hit_in_episode) {
              return
            }
          }
          // @ts-ignore
          document.getElementsByClassName("tipbox")[0].style.opacity = 1;
          localStorage.setItem("seenMessageBox", "true");
          this.handleScrollEvent();
        }
      } catch (e) {
        console.log(e)
      }

    },
    handleScrollEvent() {
      let tipbox = document.getElementsByClassName("tipbox")[0]
      document.addEventListener('scroll', function handler(e) {
        let threshold;
        const mobileThreshold = 70;
        const desktopThreshold = 45;
        // @ts-ignore
        if (getComputedStyle(document.getElementById("mobileDiv")).color === "rgba(17, 51, 51, 0.467)") {
          threshold = mobileThreshold;
        } else {
          threshold = desktopThreshold;
        }

        if (tipbox.getBoundingClientRect().top < threshold) {
          // @ts-ignore
          document.getElementsByClassName("tipbox")[0].style.opacity = 0;
          if (e.currentTarget) {
            e.currentTarget.removeEventListener("scroll", handler);
          }
        }
      })
    }
  },
  computed: {
    searchQueryEmpty() {
      if (this.query === "" || this.query === null) {
        return true
      }
      return false;
    },
    sortOrder(): SortOrder {
      const sortOrderQueryParam: string = unwrapQueryParam(this.$route.query.sort)
      const sortOrderFound = (Object.keys(SortOrder) as Array<keyof typeof SortOrder>).filter((sortOrder) => {
        if (sortOrderQueryParam === sortOrder) {
          return sortOrder
        }
      })

      if (sortOrderFound.length > 0) {
        return sortOrderFound[0] as SortOrder
      } else {
        return SortOrder.mostHits
      }

    }
  }
})
