<template>
    <div class="brand">
        <h1 class="m-0">
            <router-link to="/">
                <c-image
                    :src="require('/src/LandingPage/assets/images/logos/pearch-logo-beta-green.png')"
                    alt="Surface"
                    :width="150"
                    :height="32" />
            </router-link>
        </h1>
    </div>
</template>

<script>
import CImage from '/src/LandingPage/components/elements/Image.vue'

export default {
  name: 'CLogo',
  components: {
    CImage
  }
}
</script>

