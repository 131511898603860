export class DateUtil {

    static getFormattedDate(dateString: string) {
        let s = "";
        const hours = parseInt(dateString.substring(1, 2));
        const minutes = dateString.substring(3, 5);
        if (!isNaN(hours) && hours > 0) {
            s += `${hours}h `
        }
        s += `${minutes} min`
        return s;
    }

    static getFormattedPublishDate(publishDate: string) {
        return publishDate.slice(0)
    }

    static getFormattedTimestamp(time: number) {
        const timestamp = String(time);
        let s = "";
        const arr = timestamp.split(".");
        const min = Math.round(parseInt(arr[0]));
        s += `${min} min`
        return s;
    }

}

export default new DateUtil();
