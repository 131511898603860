<template>
  <div class="episode-query-result-list">
    <HitInEpisode
        class="episode-query-result"
        v-for="(hit, idx) in hits"
        :query="query"
        :key="idx"
        :episode-name="episodeName"
        :episode-number="episodeNumber"
        :hit="hit"
        :spotifyUrl="spotifyUrl"
    />
  </div>


</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import HitInEpisode from "@/Application/components/QueryResults/HitInEpisode.vue";
import { QueryHit } from '@/Application/model/QueryHit';

export default defineComponent({
  name: "EpisodeHitList",
  components: { HitInEpisode },
  props: {
    query: String,
    episodeName: String,
    episodeNumber: Number,
    spotifyUrl: String,
    hits: Array as PropType<QueryHit[]>
  }
});

</script>

<style scoped>
.episode-query-result-list {

}

.episode-query-result {
  margin-top: min(1.8rem, 6vw);
}

.episode-query-result:first-of-type {
  margin-top: min(1rem, 4vw);
}

.episode-query-result:last-of-type {
  margin-bottom: 2rem;
}
</style>
