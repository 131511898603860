<template>
  <div class="loader-animation">
    <div></div><div></div><div></div><div></div>
  <!---<p>Söker...</p> !-->
  </div>
</template>

<script>
export default {
name: "LoaderAnimation"
}
</script>

<style scoped>
.loader-animation {
  display: inline-block;
  position: relative;
  width: 140px;
  height: 140px;
}
.loader-animation div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100px;
  height: 100px;
  margin: 0 15px 0 15px;
  border: 8px solid #000;
  border-radius: 50%;
  animation: loader-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000 transparent transparent transparent;
}

p {
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  font-weight: 300;
  font-size: 1.2rem;
}

.loader-animation div:nth-child(1) {
  animation-delay: -0.45s;
}
.loader-animation div:nth-child(2) {
  animation-delay: -0.3s;
}
.loader-animation div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loader-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
