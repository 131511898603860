<template>
    <footer class="site-footer invert-color center-content-mobile" :class="topOuterDivider && 'has-top-divider'">
        <div class="container">
            <div class="site-footer-inner" :class="topDivider && 'has-top-divider'">
                <div class="footer-top space-between text-xxs">
                </div>
                <div class="center-content text-xxs">
                    <a style="text-decoration: none" class="text-xs"> hello@pearch.se </a>
                    <div  class="mt-8 footer-copyright">&copy; 2022 Pearch, all rights reserved</div>
                 <!-- <c-footer-nav class="footer-nav"/> -->
                </div>
            </div>
        </div>
    </footer>
</template>

<script>

export default {
  name: 'CFooter',
  components: {
  },
  props: {
    topOuterDivider: {
      type: Boolean,
      default: false
    },      
    topDivider: {
      type: Boolean,
      default: false
    }
  }
}
</script>